import styles from "@equidefi/portals/components/auth.module.css";
import React, { useMemo } from "react";
import Select from "react-select";
import { useRoleList } from "../../hooks/useIssuers";

const Role = ({
  issuerId,
  handleChange,
  name,
  handleBlur,
  setFieldValue,
  errors,
  disabled = false,
  label = "Role",
  defaultValue = null,
  classes,
}) => {
  const {data: roles = [], isLoading} = useRoleList(issuerId);
  const options = useMemo(() => {
    return roles?.map((option) => ({
        label: option?.name,
        value: option?.id,
      })) ?? []
  }, [roles]);

  const defaultRole = useMemo(() => {
    for (const role of roles) {
      if (role.name.toLowerCase() === defaultValue) {
        return {
          label: role.name,
          value: role.id
        };
      }
    }
  }, [roles, defaultValue])

  if (isLoading || !roles) return null;

  return (
    <div className={classes ? classes.formGroup : styles.formGroup}>
      {label ? (
        <label htmlFor="role" className={classes ? classes.label : styles.label}>
            {label} <span className={classes ? classes.danger : styles.danger}>*</span>
        </label>
      ) : null}
      <Select
        className="mt-2"
        name={name}
        options={options}
        onChange={(value) => {
          if (setFieldValue) setFieldValue(name, value);
          if (handleChange) handleChange(value);
        }}
        defaultValue={defaultRole}
        isOptionDisabled={(option) => option.disabled}
        isDisabled={disabled}
        isLoading={isLoading}
      />
      {errors?.role && (
        <span className={classes ? classes.danger : styles.danger}>{errors?.role}</span>
      )}
    </div>
  )
}

export default Role;
