const TOKEN_KEY = "user:token";

class UserToken {
  static token() {
    return localStorage.getItem(TOKEN_KEY);
  }

  static setToken(value) {
    localStorage.setItem(TOKEN_KEY, value);
  }

  static clear() {
    localStorage.removeItem(TOKEN_KEY);
  }
}

export default UserToken;
