import { ApiClient } from "./ApiClient";
import {CONTENT_TYPES} from "../../shared/constants/files";
import axios from "axios";

class AWSClient extends ApiClient {

  async s3Upload(url: string, binaryFile: any, fileExtension: string) {
    return axios.put(url, binaryFile, {
      transformRequest: (data, headers) => {
        delete headers.common;
        return data;
      },
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": CONTENT_TYPES[fileExtension],
        "Accept": "*/*"
      }
    });
  }
}

export default AWSClient;
