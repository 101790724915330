export const CONTENT_TYPES = {
  'png': 'image/png',
  'jpg': 'image/jpeg',
  'jpeg': 'image/jpeg',
  'tiff': 'image/tiff',
  'webp': 'image/webp',
  'heic': 'image/heic',
  'heiv': 'image/heiv',
  'pdf': 'application/pdf'
}
