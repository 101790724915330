/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import shortid from "shortid";

import FileSaver from "file-saver";
import { parse } from "json2csv/dist/json2csv.umd";
import { CSVReader } from "react-papaparse";

import
  {
    createInvestment,
    createUser,
    getOfferingById,
    getUserByEmail,
  } from "../../helpers";
import Header from "../../layouts/header";

const BulkInvite = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const offering = useSelector((state) => state.offering);

  const [contacts, setContacts] = useState([]);
  const [sendButton, disableSendButton] = useState(false);

  const buttonRef = useRef();

  useEffect(() => {
    getOfferingById(id).then((offering) => {
      dispatch({ type: "offering", payload: offering });
    });
  }, []);

  const downloadSampleFile = () => {
    const fileName = `Sample_Invite_Template`;
    const fields = ["first_name", "last_name", "email", "title", "company"];
    const fileData = [{ first_name: "", last_name: "", email: "", title: "", company: "" }];
    const csv = parse(fileData, fields);
    const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(csvData, `${fileName}.csv`);
  };

  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleUpload = (data) => {
    setContacts([]);
    const fileData = data.map((subData) => subData.data);
    if (fileData && fileData.length > 1) {
      const rows = data.map((subData) => subData.data);
      const headerRow = rows[0];
      if (
        headerRow[0].toLowerCase() === "first_name" &&
        headerRow[1].toLowerCase() === "last_name" &&
        headerRow[2].toLowerCase() === "email" &&
        headerRow[3].toLowerCase() === "title" &&
        headerRow[4].toLowerCase() === "company"
      ) {
        const contacts = rows
          .slice(1)
          // Do not include completely empty rows
          .filter((r) => r.filter(Boolean).length > 0)
          .map((item) => {
            const [first_name, last_name, email, title, company] = item;

            return {
              id: shortid.generate(),
              first_name,
              last_name,
              email,
              title,
              company,
              status: "Pending",
            };
          });

        setContacts(contacts);
        disableSendButton(false);
        toast.success(`${contacts.length} rows have been imported`);
      } else {
        toast.error("Invalid file format");
      }
    } else {
      toast.error("Invalid file format");
    }
  };

  const handleSend = async () => {
    disableSendButton(true);

    for (let i = 0; i < contacts.length; i++) {
      contacts[i].status = "Sending";
    }
    setContacts(Object.create(contacts));

    for (let i = 0; i < contacts.length; i++) {
      await sendInvite(i, contacts[i]);
      contacts[i].status = "Sent";
      setContacts(Object.create(contacts));
    }

    disableSendButton(false);
    history.push(`/offering/${offering.id}/investments`);
  };

  const sendInvite = async (index, contact) => {
    try {
      const user = await getUserByEmail(contact.email);
      await createInvestment(offering.issuer_id, offering.id, user.id);
    } catch (error) {
      const user = await createUser(contact);
      await createInvestment(offering.issuer_id, offering.id, user.id);
    }
  };

  return (
    <>
      <Header title={offering?.name} name="Bulk Invite">
        <div className="row g-2">
          <div className="col">
            <button
              className="btn btn-light text-nowrap"
              onClick={downloadSampleFile}
            >
              <i className="fe fe-download me-1"></i> Sample CSV
            </button>
          </div>
          <div className="col">
            <CSVReader
              ref={buttonRef}
              onFileLoad={handleUpload}
              noClick
              noDrag
              noProgressBar
            >
              {({ file }) => (
                <button
                  type="button"
                  onClick={handleOpenDialog}
                  className="btn btn-eq-primary"
                >
                  <i className="fe fe-upload me-1" /> Upload
                </button>
              )}
            </CSVReader>
          </div>
        </div>
      </Header>

      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            {contacts.length > 0 && (
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-eq-primary btn-sm "
                  disabled={sendButton}
                  onClick={handleSend}
                >
                  Send Invites
                </button>
              </div>
            )}
          </div>
          <div className="table-responsive">
            <table className="table table-sm table-hover table-nowrap card-table ">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>email</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {contacts.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="align-baseline">{[item.first_name, item.last_name].join(" ")}</td>
                      <td className="align-baseline">{item.email}</td>
                      <td className="align-baseline">
                        <span
                          className={`h3 ${
                            item.status === "Sent"
                              ? "text-success"
                              : "text-secondary"
                          } me-2`}
                        >
                          ●
                        </span>
                        {item.status}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkInvite;
