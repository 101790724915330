import { ApiClient } from "./ApiClient";

class BrokerClient extends ApiClient {
  async all() {
    const response = await this.get("/v2/brokers");
    return response.data;
  }

  async find(id: string) {
    const response = await this.get(`/v2/brokers/${id}`);
    return response.data;
  }

  async search(text: string) {
    const response = await this.get(`/v2/brokers/search`, {
      params: { q: text },
    });
    return response.data.map((value: Record<string, any>) => ({
      label: `${value.first_name} ${value.last_name} (${value.crd_number})`,
      value: value,
    }));
  }

  async create(data: Record<string, any>) {
    const response = await this.post("/v2/brokers", data);
    return response.data;
  }

  async update(id: string, data = {}) {
    const response = await this.put(`/v2/brokers/${id}`, data);
    return response.data;
  }
}

export default BrokerClient;
