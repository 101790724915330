import { useSelector } from "react-redux";
import BankingInfo from "../../components/invoices/BankingInfo";
import Invoices from "../../components/invoices/Invoices";
import {useInvoices} from "../../hooks/useInvoices";
import './style.css';

const InvoiceList = () => {
  const issuer = useSelector((store) => store.issuer);
  const {
    data,
    isLoading,
  } = useInvoices(issuer?.id);

  return (
    <>
      <div className="container-fluid">
        <BankingInfo />
        <Invoices invoices={data} isLoading={isLoading} />
      </div>
    </>
  );
};

export default InvoiceList;
