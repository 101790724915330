import { ApiClient } from "./ApiClient";

class InvestmentClient extends ApiClient {
  async dispatchEvent(investmentId: string, payload: Object) {
    const response = await this.post(
      `/v2/investments/${investmentId}/event`,
      payload
    );

    return response.data;
  }

  async editSigner(investmentId: string, payload: Object) {
    const response = await this.patch(
      `/v2/agreements/signers/${investmentId}`,
      payload
    );

    return response.data;
  }

  async addSigner(investmentId: string, payload: Object) {
    const response = await this.put(
      `/v2/agreements/signers/${investmentId}`,
      payload
    );

    return response.data;
  }

  async deleteSigner(signerId: string) {
    const response = await this.delete(`/v2/agreements/signers/${signerId}`);

    return response.data;
  }

  async setupSigners(investmentId: string) {
    const response = await this.post(`/v2/agreements/signers/${investmentId}`);

    return response.data;
  }

  async get(id: string) {
    // Not sure where it's coming from but id is showing as a string with the value undefined
    if (!id || id === "undefined") {
      return;
    }
    const response = await super.get(`/v2/investments/${id}`);
    return response.data;
  }

  async update(id: string, data = {}) {
    const response = await this.patch(`/v2/investments/${id}`, data);
    return response.data;
  }

  async legacyUpdateStatus(
    id: string,
    status: string,
    data: { description?: string } = {}
  ) {
    const response = await this.post(
      `/investment/${id}/status/${status}`,
      data
    );
    return response.data;
  }

  async getInquiry(id: string) {
    const response = await super.get(`/v2/investments/${id}/inquiry`);
    return response.data;
  }

  async all() {
    const response = await super.get(`/v2/investments`);
    return response.data;
  }
}

export default InvestmentClient;
