import { Alert, Box, Flex, IconButton, Spacer, Stack, HStack } from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";
import React from "react";

import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

import CopyableText from "../CopyableText";
import SendReminderButton from "./SendReminderButton";
import SigningURL from "./SigningUrl";

import { isAdmin } from "../../../../portals/issuer/src/helpers";
import { useCurrentUser } from "../../hooks/useUsers";
import { useInvestmentSignatures } from "../../hooks/useAgreements";

const SignatureIcon = ({ isChecked = false, ...rest }) => {
  if (isChecked) {
    return <Icon.CheckCircle {...rest} />;
  }

  return <Icon.Circle {...rest} />;
};

export const InvestmentSignatureBox = ({
  investmentId,
  signature,
  onRemind = () => {},
  onEdit = () => {},
  onDelete = () => {},
}) => {
  const { data: agreement } = useInvestmentSignatures(investmentId);
  const investmentAgreement = agreement?.investment_agreement;

  const { data: user } = useCurrentUser();
  const canEdit = isAdmin(user) && signature?.order && signature?.order !== 1;

  const showSigningURL = isAdmin(user) && signature?.remindable;

  return (
    <Alert
      as="li"
      status={signature.status === "signed" ? "success" : "warning"}
      borderWidth="1px"
      borderRadius="lg"
      px="3"
      py=".5"
      backgroundColor={
        signature.status === "signed"
          ? transparentize("equidefi.green", 0.2)
          : transparentize("equidefi.yellow", 0.2)
      }
      borderColor={
        signature.status === "signed" ? "equidefi.green" : "equidefi.yellow"
      }
      w="full"
      shadow="sm"
    >
      <Flex gap="2" align="center" flexFlow="row nowrap" w="full">
        <Box flexShrink={0}>
          <SignatureIcon
            isChecked={signature.status === "signed"}
            size="21px"
          />
        </Box>

        <Stack
          alignItems={{ base: "start", md: "center" }}
          direction={{ base: "column", md: "row" }}
          p="2"
          w="full"
        >
          <Text
            isTruncated
            maxW={{ base: "full", md: "25%" }}
            flexShrink="0"
            mb="0"
            as="span"
            textStyle="h3"
            fontWeight="600"
            cursor={canEdit ? "pointer" : "default"}
            onClick={
              canEdit
                ? () => {
                    onEdit({
                      email: signature?.email,
                      first_name: signature?.first_name,
                      last_name: signature?.last_name,
                      middle_name: signature?.middle_name,
                      name: signature?.name,
                      order: signature?.order,
                      phone: signature?.phone,
                      signing_party_id: signature?.signing_party_id,
                    });
                  }
                : null
            }
            textDecor={canEdit && "underline"}
          >
            {signature.name}
          </Text>

          <Stack
            direction={{ base: "column", md: "row" }}
            gap="0"
            wrap="wrap"
            maxW={{ base: "full", md: "50%" }}
          >
            <CopyableText text={signature.email} />
            <CopyableText text={signature.phone} />
          </Stack>
          <Spacer />
          <HStack mr="2">
            {
              showSigningURL && (
                <SigningURL 
                  investmentAgreementId={investmentAgreement?.id} 
                  signatureId={signature?.signature_id} 
                />
              )
            }
            <SendReminderButton
              investmentId={investmentId}
              isVisible={signature.remindable}
              signature={signature}
            />
          </HStack>
          {canEdit && (
            <IconButton
              size="1em"
              onClick={() => {
                onDelete(signature?.signing_party_id);
              }}
              variant="none"
              icon={<Icon.Trash size="1em" />}
              color="red"
            />
          )}
        </Stack>
      </Flex>
    </Alert>
  );
};
