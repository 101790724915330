import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Spinner from '../../components/spinner';
import { getPaymentMethod } from "../../hooks/useStripe";


const BankingInfo = () => {
  const [isLoading, setLoading] = useState(true);
  const issuer = useSelector((store) => store.issuer);
  const [paymentMethod, setPaymentMethod] = useState();

  useEffect(() => {
    const getAPIData = async () => {
      const result = await getPaymentMethod(issuer.id);
      setPaymentMethod(result);
    }
    if (!issuer?.id) return
    getAPIData()
      .then()
      .catch((error) => {
        if (error.response.status === 404) {
          setPaymentMethod(null);
        } else {
          const message = error?.response.data.errors.join('. ');
          toast.error(`There was a problem loading your banking info: <br/><br/> ${message}`);
        }
      }).finally(() => setLoading(false));
  }, [issuer]);

  if (isLoading) return <Spinner show={true} />;

  if (!paymentMethod) {
    return (
      <div className="card banking-info">
        <div className="row connect">
          <a href="/payment">Connect your bank to EquiDeFi</a>
        </div>
      </div>
    )
  }

  return (
    <div className="card banking-info">
      <div className="row">
        <div className="col-10 title">Banking Information</div>
        <div className="col-2 change-bank">
          <div className="change-button">
            <Link to="/payment" className="btn btn-eq-primary">Change</Link>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-nowrap table-hover mb-0">
          <thead>
            <tr>
              <th>Account Name</th>
              <th>Routing Number</th>
              <th>Account Number</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{paymentMethod.us_bank_account.bank_name}</td>
              <td>{paymentMethod.us_bank_account.routing_number}</td>
              <td>.......{paymentMethod.us_bank_account.last4}</td>
              <td>Active</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BankingInfo;
