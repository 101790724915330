import
  {
    Button,
    ButtonGroup,
    Input as CInput,
    FormControl,
    FormErrorMessage,
    FormLabel,
  } from "@chakra-ui/react";
import { Formik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Input, Textarea } from "@equidefi/ui";

import { CurrencyFormatter } from "../../../constants/format";
import { MESSAGE } from "../../../constants/forms";
import { useInvestmentAddRefund } from "../../../hooks/investments";

const InitialRefundValues = {
  reference_number: "",
  description: "",
  amount: 0,
};

const ManualRefundModal = ({
  investmentId,
  isVisible = false,
  totalPaid = 0,
  onHide = () => {},
  onSubmit = () => {},
}) => {
  const addRefund = useInvestmentAddRefund(investmentId, {
    onSuccess: () => {
      onHide();
      onSubmit();
      toast.success("Manual refund has been added");
    },
    onError: () => {
      toast.error("Something went wrong. Could not add manual refund.");
    },
  });

  const RefundSchema = Yup.object().shape({
    amount: Yup.number()
      .required(MESSAGE.REQUIRED("amount"))
      .max(
        totalPaid,
        `Cannot refund more than ${CurrencyFormatter.format(totalPaid)}`
      ),
    reference_number: Yup.string().required(
      MESSAGE.REQUIRED("reference number")
    ),
  });

  const handleManualRefund = async (data) => addRefund.mutateAsync({ data });

  return (
    <Modal centered show={isVisible} onHide={onHide}>
      <Modal.Header>Add Manual Refund</Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={InitialRefundValues}
          validationSchema={RefundSchema}
          onSubmit={handleManualRefund}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, values, errors, setValues, getFieldProps }) => (
            <form noValidate onSubmit={handleSubmit}>
              <FormControl isInvalid={errors.amount} isRequired>
                <FormLabel>Refund Amount</FormLabel>
                <NumberFormat
                  name="amount"
                  customInput={CInput}
                  value={values.amount}
                  autoFocus
                  allowNegative={false}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={({ value }) => {
                    setValues((prev) => ({
                      ...prev,
                      amount: value,
                    }));
                  }}
                />
                <FormErrorMessage>{errors.amount}</FormErrorMessage>
              </FormControl>

              <Input
                {...getFieldProps("reference_number")}
                label="FedWire/Check Number"
                labelProps={{ fontSize: "1em" }}
                isRequired
                isInvalid={errors.reference_number}
                error={errors.reference_number}
              />

              <Textarea
                {...getFieldProps("description")}
                rows={3}
                label="Description"
              />

              <ButtonGroup size="sm">
                <Button isLoading={addRefund.isLoading} type="submit">
                  Refund
                </Button>
                <Button
                  isDisabled={addRefund.isLoading}
                  colorScheme="gray"
                  variant="ghost"
                  onClick={onHide}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ManualRefundModal;
