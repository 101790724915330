import
  {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
  } from "@chakra-ui/react";
import { ButtonGroupResponsive, Heading, Textarea } from "@equidefi/ui";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { MESSAGE } from "../../../constants/forms";

const InvestmentVoidModal = ({
  isOpen = false,
  onSubmit = () => {},
  onClose = () => {},
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { reason: "" },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required(MESSAGE.REQUIRED("reason")),
    }),
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const { handleSubmit, errors, touched, getFieldProps } = formik;

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading mb="0" size="md">
            Void Agreement
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <form onSubmit={handleSubmit} noValidate>
          <ModalBody>
            <Textarea
              label="Reason"
              autoFocus
              isRequired
              isInvalid={errors.reason && touched.reason}
              error={errors.reason}
              row={3}
              {...getFieldProps("reason")}
            />
          </ModalBody>
          <ModalFooter>
            <ButtonGroupResponsive>
              <Button colorScheme="gray" variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" type="submit">
                Void
              </Button>
            </ButtonGroupResponsive>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default InvestmentVoidModal;
