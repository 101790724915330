import {
  MutationObserverOptions,
  QueryObserverOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { LDContextCommon, useLDClient } from "launchdarkly-react-client-sdk";

import { useCallback, useEffect, useMemo } from "react";
import UserClient, { TResetPasswordParams } from "../clients/UserClient";
import UserToken from "../clients/UserToken";
import { useApi } from "./useApi";

export const useCurrentUser = (options: QueryObserverOptions = {}) => {
  const userApi = useApi(UserClient);

  return useQuery({
    queryKey: ["me"],
    queryFn: () => userApi.me(),
    enabled: !!UserToken.token(),
    ...options,
  });
};

export const useGetUserById = (userId: string, options = {}) => {
  const userApi = useApi(UserClient);
  if (!userId) {
    return null;
  }
  return useQuery(
    ["users", userId],
    () => userApi.getById(userId),
    {
      ...options,
    }
  );
}

export const useUpdateUserById = (userId, options = {}) => {
  const userApi = useApi(UserClient);

  return useMutation(
    (data: Record<string, any>) => userApi.updateById(userId, data),
    options
  );
}

export const useUpdateCurrentUser = (options = {}) => {
  const userApi = useApi(UserClient);

  return useMutation(
    (data: Record<string, any>) => userApi.update(data),
    options
  );
};

type UserT = {
  email: string;
  first_name: string;
  last_name: string;
  [key: string]: any;
};

const launchDarklyUser = (user?: UserT, ip = null): LDContextCommon => {
  if (!user?.email) {
    return { anonymous: true, ip };
  }

  return {
    kind: "user",
    email: user.email,
    key: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    name: `${user.first_name} ${user.last_name}`,
    _meta: {},
  };
};

export const useIpAddress = () => {
  return useQuery(["ip-address"], async () => {
    const res = await fetch("https://api64.ipify.org/?format=json");
    const json = await res.json();
    return json?.ip;
  });
};

export const useIdentifyLDUser = (user?: UserT) => {
  const ldClient = useLDClient();
  const { data: ip } = useIpAddress();

  const data = useMemo(() => launchDarklyUser(user, ip), [user, ip]);
  const load = useCallback(async () => {
    ldClient?.identify(data);
  }, [ldClient, data, ip]);

  useEffect(() => {
    load();
  }, [ldClient, user, ip]);
};

/**
 * @param type Type of invitation, options can be INVESTOR, OFFERING or ISSUER
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInvitation = (type: string, options = {}) => {
  const userApi = useApi(UserClient);

  return useMutation(
    async (data: object) => userApi.invitation(type, data),
    options
  );
};

export const useSendResetPasswordEmail = (
  options: Omit<
    MutationObserverOptions<unknown, unknown, unknown, unknown>,
    "mutationFn"
  > = {}
) => {
  const userApi = useApi(UserClient);

  return useMutation(async (data: TResetPasswordParams) => {
    const { email, source } = data;

    await userApi.sendResetPasswordEmail({
      email: email.toLowerCase().trim(),
      source,
    });
  }, options);
};
