import { Center, Flex, IconButton, Tooltip, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";
import { usePresignedUrl } from "../../hooks/useAccreditation";
import AWSClient from "../../clients/AWSClient";
import { CONTENT_TYPES } from "@equidefi/shared/constants/files";

export const UploadComponent = ({
  investmentId,
  fileNum,
  vaultField,
  handleChange,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isUploaded, setUploaded] = useState(false);
  const [file, setFile] = useState();
  const [fileExtension, setFileExtension] = useState();
  const getPresignedUrl = usePresignedUrl(investmentId);
  const toast = useToast();

  const handleUpload = async () => {
    setLoading(true);
    const response = await getPresignedUrl.mutateAsync({
      vaultField,
      fileExtension,
    });
    const client = new AWSClient();
    client
      .s3Upload(response.signedUrl, file, fileExtension, file.size)
      .then(() => {
        toast({
          status: "success",
          description: "Your file was uploaded successfully!",
        });
        handleChange({
          target: {
            name: vaultField,
            value: response.url,
          },
        });
        setUploaded(true);
      })
      .catch((e) => {
        console.log(e);
        toast({
          status: "error",
          description: "Your file failed to upload!",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const Action = () => {
    return (
      <Tooltip label="Upload file" aria-label="Upload file" hasArrow>
        <IconButton
          isDisabled={!file}
          background="primary"
          isLoading={isLoading}
          onClick={(e) => {
            e.preventDefault();
            handleUpload();
          }}
          minW="12"
          h="12"
          colorScheme="black"
          icon={<Icon.Upload size="1em" />}
          aria-label="Upload file"
        />
      </Tooltip>
    );
  };

  if (isUploaded) {
    return (
      <Center
        key={fileNum}
        background="equidefi.green"
        borderRadius="8px"
        color="white"
        width="full"
        height="12"
        mb="7px"
      >
        <Icon.Lock size="1em" />
        <Text m="0">Secure Upload Complete</Text>
      </Center>
    );
  }

  return (
    <Flex key={fileNum} minW="full" gap="1">
      <input
        type="file"
        onChange={(event) => {
          if (!event.target.files[0]) {
            return; // exit, no file selected
          }
          const ext = event.target.files[0].name.split(".").pop().toLowerCase();
          if (!Object.keys(CONTENT_TYPES).includes(ext)) {
            toast({
              type: "error",
              description: `You cannot upload a ${ext} file type, please upload one of these supported types: ${Object.keys(
                CONTENT_TYPES
              ).join(", ")}`,
            });
            return;
          }
          setFile(event.target.files[0]);
          setFileExtension(ext);
        }}
        style={{
          padding: "8px 0 0 8px",
          backgroundColor: "#dfd9ea",
          borderRadius: "8px",
          margin: 0,
          border: "1px dashed #7283a0",
          maxWidth: "90%",
        }}
      />
      <Action />
    </Flex>
  );
};
