import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
import { regex_password, regex_phone, regex_website } from "../helpers/utils";

export const MESSAGE = {
  REQUIRED: (value = null) =>
    value ? `The ${value} field is required.` : "This is a required field.",
  NAME_VALID: (value) => `Please enter a valid ${value}`,
  MAX: (value) => `Cannot be more than ${value} characters`,
  CONFIRM_PASS: "Please enter the password again.",
  EMAIL_VALID: "Please enter a valid email.",
  PHONE_VALID: "Phone number is not valid.",
  NUMBER_VALID: "Please provide a number.",
  ZIPCOD_VALID: "Zipcode is not valid.",
  ACCOUNT_VALID: "Enter a valid US bank account number.",
  ACCOUNT_MATCH: "Account number must match.",
  ACH_VALID: "Enter a valid ACH routing number.",
  ACH_MATCH: "ACH routing number must match.",
  COMPANY_NAME_VALID: "Provide a valid company name.",
  DECIMAL_VALID: "Value should not be in decimal format.",
  URL_VALID: "URL is not valid",
  MIN_VALID: "Please provide value more than 0",
};

const REGEX = {
  NAME: /^[A-Za-z ]*$/,
  ZIPCODE: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,}$/,
  COMPANY_NAME:
    /^[a-zA-Z0-9-@.{}#$&!;:,`()|]+(\s[a-zA-Z0-9-@.{}#$&!;:,`()|]+)+(\s[a-zA-Z0-9-@.{}#$&!;:,`()|]+)?$/,
};

export const issuerCreateSchema = Yup.object().shape({
  name: Yup.string()
    .matches(REGEX.COMPANY_NAME, MESSAGE.COMPANY_NAME_VALID)
    .max(150)
    .trim()
    .required(MESSAGE.REQUIRED("name")),
  email: Yup.string()
    .email(MESSAGE.EMAIL_VALID)
    .required(MESSAGE.REQUIRED("email")),
  phone: Yup.string()
    .matches(regex_phone, MESSAGE.PHONE_VALID)
    .required(MESSAGE.REQUIRED("phone")),
  sec_phone: Yup.string().matches(regex_phone).nullable(),
  linkedin_url: Yup.string()
    .matches(regex_website, MESSAGE.URL_VALID)
    .nullable(),
  website_url: Yup.string()
    .matches(regex_website, MESSAGE.URL_VALID)
    .nullable()
    .required(MESSAGE.REQUIRED("website URL")),
  cik: Yup.string().nullable(),
  ein: Yup.string().nullable(), // need to check the database for the ein is a string
  sic: Yup.number().nullable(),
  state_of_incorporation: Yup.string().nullable(),
  doing_business_as: Yup.string().nullable(),
  address: Yup.object().shape({
    line_1: Yup.string().required("Please enter your street address"),
    city: Yup.string().required("Please enter your city"),
    state: Yup.string().required("Please enter your state"),
    postal_code: Yup.string().required("Please enter your postal code"),
  }),
  business_address: Yup.object().shape({
    line_1: Yup.string().required("Please enter your street address"),
    city: Yup.string().required("Please enter your city"),
    state: Yup.string().required("Please enter your state"),
    postal_code: Yup.string().required("Please enter your postal code"),
  }),
});

export const issuerUserSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(REGEX.NAME, MESSAGE.NAME_VALID("first name"))
    .min(2)
    .max(60, MESSAGE.MAX(60))
    .required(MESSAGE.REQUIRED("first name")),
  middle_name: Yup.string()
    .matches(REGEX.NAME, MESSAGE.NAME_VALID("middle name"))
    .max(60, MESSAGE.MAX(60))
    .nullable(),
  last_name: Yup.string()
    .matches(REGEX.NAME, MESSAGE.NAME_VALID("last name"))
    .min(2)
    .max(60, MESSAGE.MAX(60))
    .required(MESSAGE.REQUIRED("last name")),
  email: Yup.string()
    .email(MESSAGE.EMAIL_VALID)
    .required(MESSAGE.REQUIRED("email")),
  phone: Yup.string().test({
    name: "phone",
    message: "Must be a valid phone number",
    test: (value) => (!!value ? isValidPhoneNumber(value) : false),
  }),
  password: Yup.string()
    .required("Please enter a password")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%^*#?&])[A-Za-z\d@$!%*^#?&]{8,}$/,
      "Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character"
    ),
  confirm_password: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const completeProfileFormSchema = Yup.object().shape({
  name: Yup.string().required(MESSAGE.REQUIRED("name")),
  company: Yup.string().required(MESSAGE.REQUIRED()),
  title: Yup.string().required(MESSAGE.REQUIRED()),
  email: Yup.string()
    .email(MESSAGE.EMAIL_VALID)
    .required(MESSAGE.REQUIRED("email")),
  password: Yup.string()
    .required(MESSAGE.REQUIRED("password"))
    .matches(
      REGEX.PASSWORD,
      "Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character"
    ),
  confirmPassword: Yup.string()
    .required(MESSAGE.CONFIRM_PASS)
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const loginFormSchema = Yup.object().shape({
  email: Yup.string()
    .email(MESSAGE.EMAIL_VALID)
    .required(MESSAGE.REQUIRED("email")),
  password: Yup.string().required(MESSAGE.REQUIRED("password")),
});

export const forgotFormSchema = Yup.object().shape({
  email: Yup.string()
    .email(MESSAGE.EMAIL_VALID)
    .required(MESSAGE.REQUIRED("email")),
});

export const resetFormSchema = Yup.object().shape({
  password: Yup.string()
    .required(MESSAGE.REQUIRED("password"))
    .matches(
      regex_password,
      "Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character"
    ),
  confirmPassword: Yup.string()
    .required(MESSAGE.CONFIRM_PASS)
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const contractFormSchema = Yup.object().shape({
  first_name: Yup.string().required(MESSAGE.REQUIRED("first name")),
  middle_name: Yup.string().nullable(true),
  last_name: Yup.string().required(MESSAGE.REQUIRED("last name")),
  company: Yup.string().required(MESSAGE.REQUIRED()),
  title: Yup.string().required(MESSAGE.REQUIRED()),
  email: Yup.string()
    .email(MESSAGE.EMAIL_VALID)
    .required(MESSAGE.REQUIRED("email")),
  type: Yup.string().required(MESSAGE.REQUIRED()),
  phone: Yup.string().matches(regex_phone, MESSAGE.PHONE_VALID),
  portal_role: Yup.string().when("portal_access", (data) => {
    if (data === true) return Yup.string().required(MESSAGE.REQUIRED());
  }),
});

export const createOfferingSchema = Yup.object().shape({
  stock_type: Yup.string().required(MESSAGE.REQUIRED()),
  issuer: Yup.string().required(MESSAGE.REQUIRED()),
  name: Yup.string().trim().required(MESSAGE.REQUIRED("name")),

  unit_price: Yup.number().when(["stock_type", "in_lieu_of_cash_sub_type"], {
    is: (stock_type, in_lieu_of_cash_sub_type) => {
      return (
        stock_type === "common_stock" ||
        (stock_type === "in_lieu_of_cash" &&
          in_lieu_of_cash_sub_type === "in_lieu_of_cash_common_stock") ||
        stock_type === "unit" ||
        stock_type === "preferred_stock"
      );
    },
    then: Yup.number()
      .min(1, MESSAGE.MIN_VALID)
      .required(MESSAGE.REQUIRED(""))
      .typeError(MESSAGE.NUMBER_VALID),
  }),

  total_units: Yup.number().when(
    ["stock_type", "allow_total_units", "in_lieu_of_cash_sub_type"],
    {
      is: (stock_type, allow_total_units, in_lieu_of_cash_sub_type) => {
        return (
          ((stock_type === "convertible_note" ||
            stock_type === "common_stock" ||
            stock_type === "preferred_stock" ||
            stock_type === "safe" ||
            stock_type === "in_lieu_of_cash" ||
            in_lieu_of_cash_sub_type === "in_lieu_of_cash_common_stock" ||
            stock_type === "unit") &&
            allow_total_units) ||
          (stock_type === "unit" && !allow_total_units)
        );
      },
      then: Yup.number()
        .min(1, MESSAGE.MIN_VALID)
        .required(MESSAGE.REQUIRED(""))
        .typeError(MESSAGE.NUMBER_VALID),
    }
  ),

  total_amount: Yup.number().when(
    [
      "stock_type",
      "allow_total_units",
      "in_lieu_of_cash_sub_type",
      "unit_sub_type1",
    ],
    {
      is: (
        stock_type,
        allow_total_units,
        in_lieu_of_cash_sub_type,
        unit_sub_type1
      ) => {
        return (
          (stock_type === "convertible_note" ||
            stock_type === "common_stock" ||
            stock_type === "preferred_stock" ||
            stock_type === "safe" ||
            (stock_type === "in_lieu_of_cash" &&
              in_lieu_of_cash_sub_type === "in_lieu_of_cash_common_stock") ||
            (stock_type === "unit" &&
              unit_sub_type1 === "unit_convertable_notes")) &&
          !allow_total_units
        );
      },
      then: Yup.number()
        .min(1, MESSAGE.MIN_VALID)
        .required("This is a required field")
        .typeError("This is a required field"),
    }
  ),

  price_per_batch: Yup.number().when("batch_shares", (data) => {
    if (data)
      return Yup.number()
        .min(1, MESSAGE.MIN_VALID)
        .required(MESSAGE.REQUIRED())
        .typeError(MESSAGE.NUMBER_VALID);
  }),

  conversion_price: Yup.number().when(
    ["stock_type", "in_lieu_of_cash_sub_type", "unit_sub_type1"],
    {
      is: (stockType, in_lieu_of_cash_sub_type, unitSubType1) => {
        return (
          stockType === "convertible_note" ||
          (stockType === "in_lieu_of_cash" &&
            in_lieu_of_cash_sub_type === "in_lieu_of_cash_convertible_note") ||
          (stockType === "unit" && unitSubType1 === "unit_convertable_notes")
        );
      },
      then: Yup.number()
        .min(1, MESSAGE.MIN_VALID)
        .required(MESSAGE.REQUIRED())
        .typeError(MESSAGE.NUMBER_VALID),
    }
  ),

  annual_rate: Yup.number().when("stock_type", (data) => {
    if (data === "convertible_note")
      return Yup.number()
        .min(1, MESSAGE.MIN_VALID)
        .required(MESSAGE.REQUIRED())
        .typeError(MESSAGE.NUMBER_VALID);
  }),

  default_rate: Yup.number().when("stock_type", (data) => {
    if (data === "convertible_note")
      return Yup.number()
        .min(1, MESSAGE.MIN_VALID)
        .required(MESSAGE.REQUIRED())
        .typeError(MESSAGE.NUMBER_VALID);
  }),

  safe_sub_type: Yup.string().when("stock_type", (data) => {
    if (data === "safe") {
      return Yup.string().required(MESSAGE.REQUIRED());
    }
  }),

  in_lieu_of_cash_sub_type: Yup.string().when("stock_type", (data) => {
    if (data === "in_lieu_of_cash") {
      return Yup.string().required(MESSAGE.REQUIRED());
    }
  }),

  safe_pm_valuation_cap: Yup.number().when(["stock_type", "safe_sub_type"], {
    is: (stock_type, safe_sub_type) => {
      return (
        safe_sub_type === "post_money_valuation_cap" && stock_type === "safe"
      );
    },
    then: Yup.number().required(MESSAGE.REQUIRED()),
    otherwise: Yup.number().notRequired(),
  }),

  term: Yup.number().when(
    [
      "stock_type",
      "in_lieu_of_cash_sub_type",
      "unit_sub_type1",
      "enter_maturity_date",
      "unit_sub_type2",
    ],
    {
      is: (
        stock_type,
        in_lieu_of_cash_sub_type,
        unit_sub_type1,
        enter_maturity_date,
        unit_sub_type2
      ) => {
        return (
          ((stock_type === "convertible_note" ||
            (stock_type === "in_lieu_of_cash" &&
              in_lieu_of_cash_sub_type ===
                "in_lieu_of_cash_convertible_note") ||
            (stock_type === "unit" && unit_sub_type2 === "with_warrants")) &&
            !enter_maturity_date) ||
          (stock_type === "unit" && unit_sub_type1 === "unit_convertable_notes")
        );
      },
      then: Yup.number()
        .min(1, MESSAGE.MIN_VALID)
        .required(MESSAGE.REQUIRED())
        .typeError(MESSAGE.NUMBER_VALID),
    }
  ),

  in_lieu_class_subtype: Yup.string().when(
    ["stock_type", "in_lieu_of_cash_sub_type"],
    {
      is: (stock_type, in_lieu_of_cash_sub_type) => {
        return (
          stock_type === "in_lieu_of_cash" &&
          in_lieu_of_cash_sub_type === "in_lieu_of_cash_common_stock"
        );
      },
      then: Yup.string().required(MESSAGE.REQUIRED()),
    }
  ),

  common_stock_in_lieu_class: Yup.string().when(
    ["stock_type", "in_lieu_of_cash_sub_type", "in_lieu_class_subtype"],
    {
      is: (stock_type, in_lieu_of_cash_sub_type, in_lieu_class_subtype) => {
        return (
          stock_type === "in_lieu_of_cash" &&
          in_lieu_of_cash_sub_type === "in_lieu_of_cash_common_stock" &&
          in_lieu_class_subtype === "class"
        );
      },
      then: Yup.string().required(MESSAGE.REQUIRED()),
    }
  ),

  units_common_stock_share: Yup.number().when(
    ["stock_type", "unit_sub_type1"],
    {
      is: (stock_type, unit_sub_type1) => {
        return stock_type === "unit" && unit_sub_type1 === "unit_common_stock";
      },
      then: Yup.number().min(1, MESSAGE.MIN_VALID).required(MESSAGE.REQUIRED()),
    }
  ),

  units_preferred_stock_shares: Yup.number().when(
    ["stock_type", "unit_sub_type1", "unit_sub_type2"],
    {
      is: (stockType, unitSubType1) => {
        if (stockType !== "unit") return false;

        return unitSubType1 === "unit_preferred_stock";
      },
      then: Yup.number().min(1, MESSAGE.MIN_VALID).required(MESSAGE.REQUIRED()),
    }
  ),

  units_preferred_stock_shares_2: Yup.number().when(
    ["stock_type", "unit_sub_type2"],
    {
      is: (stockType, unitSubType2) => {
        if (stockType !== "unit") return false;

        return unitSubType2 === "unit_preferred_stock";
      },
      then: Yup.number().min(1, MESSAGE.MIN_VALID).required(MESSAGE.REQUIRED()),
    }
  ),

  units_warrants_percent: Yup.number().when(["stock_type", "unit_sub_type2"], {
    is: (stock_type, unit_sub_type2) => {
      return stock_type === "unit" && unit_sub_type2 === "with_warrants";
    },
    then: Yup.number().min(1, MESSAGE.MIN_VALID).required(MESSAGE.REQUIRED()),
  }),

  unit_exercise_price: Yup.number()
    .nullable()
    .when(["stock_type", "unit_sub_type2"], {
      is: (stockType, unit_sub_type2) => {
        if (stockType !== "unit") return false;
        return unit_sub_type2 === "with_warrants";
      },
      then: Yup.number().min(1, MESSAGE.MIN_VALID).required(MESSAGE.REQUIRED()),
    }),

  unit_exercise_price_2: Yup.number()
    .min(1, MESSAGE.MIN_VALID)
    .nullable()
    .typeError(MESSAGE.NUMBER_VALID),

  term_2: Yup.number().when(["stock_type", "unit_exercise_price_2", "term"], {
    is: (stockType, unitExercisePrice2, term) => {
      return stockType === "unit" && unitExercisePrice2 !== null && !!term;
    },
    then: Yup.number()
      .min(1, MESSAGE.MIN_VALID)
      .required(MESSAGE.REQUIRED("Term 2 (In Months)"))
      .moreThan(
        Yup.ref("term"),
        "Term 2 (In Months) must be greater than the Term (In Months)"
      ),
  }),

  unit_sub_type1: Yup.string().when(["stock_type"], {
    is: (stockType) => stockType === "unit",
    then: Yup.string().required(MESSAGE.REQUIRED()),
  }),

  unit_sub_type2: Yup.string().when(["stock_type"], {
    is: (stockType) => stockType === "unit",
    then: Yup.string().required(MESSAGE.REQUIRED()),
  }),

  safe_discount: Yup.number().when("safe_sub_type", (data) => {
    if (data === "discount_rate") {
      return Yup.number()
        .min(1, MESSAGE.MIN_VALID)
        .required(MESSAGE.REQUIRED());
    }
  }),

  safe_amount: Yup.number().when("safe_sub_type", (data) => {
    if (data === "valuation_cap_with_discount") {
      return Yup.number()
        .min(1, MESSAGE.MIN_VALID)
        .required(MESSAGE.REQUIRED());
    }
  }),

  safe_percentage: Yup.number().when("safe_sub_type", (data) => {
    if (data === "valuation_cap_with_discount") {
      return Yup.number()
        .min(1, MESSAGE.MIN_VALID)
        .required(MESSAGE.REQUIRED());
    }
  }),

  sub_type: Yup.string().when("stock_type", (data) => {
    if (data === "common_stock") {
      return Yup.string().required(MESSAGE.REQUIRED());
    }
  }),

  common_stock_class: Yup.string().when(["stock_type", "sub_type"], {
    is: (stock_type, sub_type) => {
      return sub_type === "class" && stock_type === "common_stock";
    },
    then: Yup.string().required(MESSAGE.REQUIRED()),
    otherwise: Yup.string().notRequired(),
  }),

  preferred_sub_type: Yup.string().when("stock_type", (data) => {
    if (data === "preferred_stock") {
      return Yup.string().required(MESSAGE.REQUIRED());
    }
  }),

  preferred_class: Yup.string().when("preferred_sub_type", (data) => {
    if (data === "series") {
      return Yup.string().required(MESSAGE.REQUIRED());
    }
  }),

  preferred_series: Yup.string().when("preferred_sub_type", (data) => {
    if (data === "series") {
      return Yup.string().required(MESSAGE.REQUIRED());
    }
  }),

  preferred_percentage: Yup.number().when("preferred_series", (data) => {
    if (data === "percentage") {
      return Yup.number()
        .min(1, MESSAGE.MIN_VALID)
        .required(MESSAGE.REQUIRED());
    }
  }),

  enter_maturity_date: Yup.boolean(),
  maturity_date: Yup.date()
    .min(new Date(), "Please select valid time")
    .when("enter_maturity_date", {
      is: true,
      then: Yup.date().required(MESSAGE.REQUIRED()),
    })
    .typeError(MESSAGE.REQUIRED()),
});

export const eventFormSchema = Yup.object().shape({
  name: Yup.string().required(MESSAGE.REQUIRED("name")),
  date: Yup.date()
    .min(new Date(), "Please select valid time")
    .required(MESSAGE.REQUIRED())
    .typeError("This is a required field"),
  timezone: Yup.string().required(MESSAGE.REQUIRED()),
  event_url: Yup.number().when("allow_offline", (data) => {
    if (!data) return Yup.string().required(MESSAGE.REQUIRED());
  }),
  address: Yup.string().when("allow_offline", (data) => {
    if (data)
      return Yup.object().shape({
        postal_code: Yup.string()
          .required(MESSAGE.REQUIRED())
          .matches(REGEX.ZIPCODE, MESSAGE.ZIPCOD_VALID),
        city: Yup.string().required(MESSAGE.REQUIRED()),
        line_1: Yup.string().required(MESSAGE.REQUIRED()),
        state: Yup.string().required(MESSAGE.REQUIRED()),
      });
  }),
});

export const accountFormSchema = Yup.object().shape({
  payment_bank_name: Yup.string()
    .when("need_escrow_agent", (data) => {
      if (data === "No") return Yup.string().required(MESSAGE.REQUIRED());
    })
    .when("has_escrow", (data) => {
      if (data === "Yes") return Yup.string().required(MESSAGE.REQUIRED());
    }),
  payment_account_name: Yup.string()
    .when("need_escrow_agent", (data) => {
      if (data === "No") return Yup.string().required(MESSAGE.REQUIRED());
    })
    .when("has_escrow", (data) => {
      if (data === "Yes") return Yup.string().required(MESSAGE.REQUIRED());
    }),
  payment_account_number: Yup.string()
    .when("need_escrow_agent", (data) => {
      if (data === "No")
        return Yup.string()
          .required(MESSAGE.REQUIRED())
          .matches("^[0-9]{7,14}$", MESSAGE.ACCOUNT_VALID);
    })
    .when("has_escrow", (data) => {
      if (data === "Yes")
        return Yup.string()
          .required(MESSAGE.REQUIRED())
          .matches("^[0-9]{7,14}$", MESSAGE.ACCOUNT_VALID);
    }),
  payment_account_number_confirm: Yup.string()
    .when("need_escrow_agent", (data) => {
      if (data === "No")
        return Yup.string().oneOf(
          [Yup.ref("payment_account_number"), null],
          MESSAGE.ACCOUNT_MATCH
        );
    })
    .when("has_escrow", (data) => {
      if (data === "Yes")
        return Yup.string().oneOf(
          [Yup.ref("payment_account_number"), null],
          MESSAGE.ACCOUNT_MATCH
        );
    }),
  payment_routing_number: Yup.string()
    .when("need_escrow_agent", (data) => {
      if (data === "No")
        return Yup.string()
          .required(MESSAGE.REQUIRED())
          .matches("^[0-9]{9}$", MESSAGE.ACH_VALID);
    })
    .when("has_escrow", (data) => {
      if (data === "Yes")
        return Yup.string()
          .required(MESSAGE.REQUIRED())
          .matches("^[0-9]{9}$", MESSAGE.ACH_VALID);
    }),
  payment_routing_number_confirm: Yup.string()
    .when("need_escrow_agent", (data) => {
      if (data === "No")
        return Yup.string().oneOf(
          [Yup.ref("payment_routing_number"), null],
          MESSAGE.ACH_MATCH
        );
    })
    .when("has_escrow", (data) => {
      if (data === "Yes")
        return Yup.string().oneOf(
          [Yup.ref("payment_routing_number"), null],
          MESSAGE.ACH_MATCH
        );
    }),
});

export const profileFormSchema = Yup.object().shape({
  name: Yup.string().required(MESSAGE.REQUIRED("name")),
  description: Yup.string().nullable().required(MESSAGE.REQUIRED()),
  stock_ticker: Yup.string().nullable(),
});

export const brokerFormSchema = Yup.object().shape({
  first_name: Yup.string()
    .nullable()
    .required(MESSAGE.REQUIRED("first name"))
    .max(50),
  last_name: Yup.string()
    .nullable()
    .required(MESSAGE.REQUIRED("last name"))
    .max(50),
  company: Yup.string().nullable(),
  code: Yup.string().required(
    MESSAGE.REQUIRED("You must enter a unique code for the broker")
  ),
  crd_number: Yup.number()
    .required(MESSAGE.REQUIRED("CRD Number"))
    .typeError("CRD must be a number"),
});
