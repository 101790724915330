import { ApiClient } from "@equidefi/portals/clients";
import { ApiInstance } from "./api";

class InvestmentClient extends ApiClient {
  /**
   * @param {string} id
   */
  async getAgreements(id) {
    const response = await this.get(`/v2/investments/${id}/agreements`);
    return response.data;
  }

  /**
   * @param {string} id
   * @returns
   */
  async legacyClose(id) {
    const response = await this.post(`/investment/close`, [id]);
    return response.data;
  }

  async reject(id) {
    const response = await this.delete(`/v2/investments/${id}`);
    return response.data;
  }

  /**
   * @param {string} id
   * @param {string} stage
   * @param {object} data
   * @return {Promise<object>}
   */
  static async updateStage(id, stage, data = {}) {
    const response = await ApiInstance.patch(
      `/v2/investments/${id}/stage/${stage}`,
      data
    );
    return response.data;
  }

  /**
   * @param {string} id
   * @param {object} data
   * @return {Promise<object>}
   */
  static async legacyAddRefund(id, data = {}) {
    const response = await ApiInstance.post(`/investment/${id}/refund`, data);
    return response.data;
  }

  /**
   * @param {string} id
   * @param {object} data
   * @return {Promise<object>}
   */
  static async legacyAddManualPayment(id, data = {}) {
    const response = await ApiInstance.post(`/investment/${id}/payment`, data);
    return response.data;
  }

  /**
   * @param {string} id
   * @param {{ status: "APPROVE" | "DECLINE", comment?: string }} data
   * @return {Promise<object>}
   */
  static async updateInquiryStatus(id, data = {}) {
    const response = await ApiInstance.put(
      `/v2/investments/${id}/inquiry/status`,
      data
    );
    return response.data;
  }

  static async getAgreementDocusignUrl(id, envelopeId, type) {
    const response = await ApiInstance.get(
      `/investment/${id}/agreement/${envelopeId}/url/${type}`
    );
    return response.data;
  }

  static async pokeInvestor(investmentId) {
    const response = await ApiInstance.post(
      `/v2/investments/${investmentId}/poke`
    );

    return response.data;
  }
}

export default InvestmentClient;
