import { Email } from "@equidefi/portals/components/Auth";
import { useInvitation } from "@equidefi/portals/hooks/useUsers";
import { Formik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ISSUER } from "../../constants/issuer";
import { useIssuerOfferings } from "../../hooks/useOfferings";
import styles from "../../pages/issuer/team/styles.module.css";
import Spinner from "../spinner";
import Role from "./Role";
import { invitationSchema } from "./schemas";


const TeamInviteModal = ({issuerId, show, onHide}) => {
    const invitation = useInvitation(ISSUER);
    const {data: offerings} = useIssuerOfferings(issuerId);
    const [access, setAccess] = useState({
      admin: false,
      offering: {},
      issuer: {}
    });

    const onSubmit = async (values) => {
      try {
        await invitation.mutateAsync({
          email: values.email,
          role_id: values.role.value,
          issuer_id: issuerId,
          access: access
        });
        toast.success(`Your invitation has been sent to ${values.email}.`);
      } catch (error) {
        if (error.response.status === 409) {
          toast.error("This user has already been added to this issuer");
        } else {
          toast.error("There was an error inviting this teammate");
        }
      } finally {
        onHide();
      }
    }

    if (!issuerId) return null;

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="my-modal"
      >
        <Modal.Body>
          <Spinner show={invitation.isLoading}>
            <h1>Invite Teammate</h1>
            <Formik
              enableReinitialize={true}
              initialValues={{
                email: "",
                role: ""
              }}
              validationSchema={invitationSchema}
              onSubmit={onSubmit}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ handleChange, handleSubmit, handleBlur, setFieldValue, values, errors }) => {
                return (
                  <form className={styles.form} onSubmit={handleSubmit}>
                    <Role
                        handleBlur={handleBlur}
                        errors={errors}
                        label="Select member's role"
                        values={values}
                        issuerId={issuerId}
                        handleChange={(value) => {
                          access.issuer[issuerId] = value.label.toLowerCase()
                          setFieldValue('role', value);
                        }}
                    />
                    <Email
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        values={values}
                        getCurrentUser={false}
                    />
                    <div className={styles.tableLabel}>Allow access to the following offerings:</div>
                    <div className="card mt-3">
                      <div className="table-response">
                        <table className="table card-table">
                          <thead>
                            <tr>
                              <td>Offering</td>
                              <td>Role</td>
                            </tr>
                          </thead>
                          <tbody>
                          {offerings?.map((offering, index) => {
                            return (
                              <tr key={index}>
                                <td>{offering.name}</td>
                                <td className={styles.roleSelectCell}>
                                  <Role
                                    handleChange={(value) => {
                                      access.offering[offering.id] = value.label.toLowerCase();
                                      setAccess(access);
                                    }}
                                    defaultValue={{
                                      value: undefined,
                                      label: "None"
                                    }}
                                    label={null}
                                    issuerId={issuerId}
                                />
                                </td>
                              </tr>
                            )
                          })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <Spinner show={invitation.isLoading}>
                      <button className="btn btn-eq-primary" type="submit">
                        Send Invitation
                      </button>
                    </Spinner>
                  </form>
                )
              }}
            </Formik>
          </Spinner>
        </Modal.Body>
      </Modal>
    )
}

export default TeamInviteModal;
