/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import swal from "sweetalert";

import FormControl from "../../components/formControl";
import Spinner from "../../components/spinner";
import Header from "../../layouts/header";

import
  {
    createOfferingContact,
    createUser,
    deleteOfferingContact,
    getUserByEmail,
    isAdmin,
    isOfferingEditor,
    isOfferingOwner,
    removeUserFromOffering,
    updateOfferingContact,
    updateOfferingUserRole,
  } from "../../helpers";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { contractFormSchema } from "../../constants/forms";
import contactTypes from "../../data/contact_types.json";
import roles from "../../data/roles.json";
import { onCleanForm } from "../../helpers/form";
import { useOffering } from "../../hooks/useOfferings";

const defaultFormData = {
  id: "",
  name: "",
  title: "",
  company: "",
  email: "",
  type: "",
  portal_access: false,
  portal_role: "",
};

const Contact = (props) => {
  const { id } = useParams();
  const { data: me } = useCurrentUser();

  const [modal, showModal] = useState(false);
  const [spinner, showSpinner] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);
  const { data: offering, refetch, isLoading, isRefetching } = useOffering(id);

  const canEdit = useMemo(() => {
    return (
      (isAdmin(me) ||
        isOfferingEditor(me, offering?.id) ||
        isOfferingOwner(me, offering?.id))
    );
  }, [me, offering?.status, offering?.id]);

  useEffect(() => {
    showSpinner(isLoading || isRefetching);
  }, [isLoading, isRefetching]);

  const handleSubmit = async (form) => {
    showSpinner(true);

    const data = {
      first_name: form.first_name,
      middle_name: form.middle_name === "" ? null : form.middle_name,
      last_name: form.last_name,
      type: form.type,
      company: form.company,
      phone: form.phone,
      email: form.email,
      title: form.title,
      portal_access: form.portal_access,
      portal_role: form.portal_access ? form.portal_role : "",
    };

    if (form.id) {
      await updateOfferingContact(id, form.id, data);
    } else {
      await createOfferingContact(id, data);
    }

    if (form.portal_access) handleUserRole(data);
    await refetch();

    showModal(false);
  };

  const handleDelete = async (contact) => {
    const status = await swal({
      title: `Remove Contact`,
      text: `Are you sure you want to remove ${contact.name}?`,
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    });

    if (status) {
      showSpinner(true);

      getUserByEmail(contact.email)
        .then(async (user) => {
          await removeUserFromOffering(user.id, id);
          await deleteOfferingContact(id, contact.id);
        })
        .catch(async () => {
          await deleteOfferingContact(id, contact.id);
        })
        .finally(async () => {
          await refetch();
          showSpinner(false);
        });
    }
  };

  const handleUserRole = (data) => {
    getUserByEmail(data.email)
      .then((user) =>
        updateOfferingUserRole(user.id, offering.id, data.portal_role)
      )
      .catch((_) =>
        createUser(data).then((user) =>
          updateOfferingUserRole(user.id, offering.id, data.portal_role)
        )
      );
  };

  return (
    <>
      <Header id={id} name="Authorized Person" title={offering?.name}>
        {canEdit && (
          <button
            className="btn btn-eq-primary"
            onClick={() => {
              setFormData(defaultFormData);
              showModal(true);
            }}
          >
            Add Contact
          </button>
        )}
      </Header>
      <div className="container-fluid">
        <Spinner show={spinner}>
          <div className="card">
            {offering?.contacts?.length > 0 && (
              <div className="table-responsive">
                <table className="table table-nowrap">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Title/Company</th>
                      <th>Type</th>
                      <th>Role</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {offering?.contacts?.map((contact) => {
                      return (
                        <tr key={contact.id}>
                          <td className="align-baseline">
                            <div>{contact.name}</div>
                            <div className="text-muted">{contact.email}</div>
                          </td>
                          <td className="align-baseline">
                            <div>{contact.title}</div>
                            <div className="text-muted">{`${contact.company}`}</div>
                          </td>
                          <td className="align-baseline">{contact.type}</td>
                          <td className="text-capitalize align-baseline">
                            {contact.portal_role}
                          </td>
                          <td className="text-end align-baseline">
                            {canEdit && (
                              <button
                                className="btn btn-sm btn-light"
                                onClick={() => {
                                  setFormData(contact);
                                  showModal(true);
                                }}
                              >
                                <i className="fe fe-edit" />
                              </button>
                            )}
                            {canEdit && (
                              <button
                                className="btn btn-sm btn-light ms-2"
                                onClick={() => handleDelete(contact)}
                              >
                                <i className="fe fe-trash" />
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Spinner>

        <Modal
          show={modal}
          onHide={() => showModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="my-modal"
        >
          <Modal.Body>
            <Spinner show={spinner}>
              <h1>Edit Contact</h1>
              <Formik
                enableReinitialize={true}
                initialValues={formData}
                validationSchema={contractFormSchema}
                onSubmit={(values) => onCleanForm(values, handleSubmit)}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {({
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  values,
                  errors,
                }) => {
                  const selectedType = values.type
                    ? contactTypes.filter((x) => x.value === values.type)[0]
                    : null;
                  const selectedRole = values.portal_role
                    ? roles.filter((x) => x.value === values.portal_role)[0]
                    : null;
                  return (
                    <form noValidate onSubmit={handleSubmit}>
                      <FormControl
                        type="select"
                        name="type"
                        title="Contact Type"
                        required={true}
                        options={contactTypes}
                        value={selectedType}
                        error={errors.type}
                        onChange={handleChange}
                      />

                      {!values.id && (
                        <>
                          <div className="row">
                            <div className="col-4">
                              <FormControl
                                type="text"
                                name="first_name"
                                title="First Name"
                                required={true}
                                placeholder="John"
                                value={values.first_name}
                                error={errors.first_name}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-4">
                              <FormControl
                                type="text"
                                name="middle_name"
                                title="Middle Name"
                                placeholder="J."
                                value={values.middle_name}
                                error={errors.middle_name}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-4">
                              <FormControl
                                type="text"
                                name="last_name"
                                title="Last Name"
                                required={true}
                                placeholder="Doe"
                                value={values.last_name}
                                error={errors.last_name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <FormControl
                            type="text"
                            name="company"
                            title="Company / Firm"
                            required={true}
                            placeholder="eg EquiDeFi"
                            value={values.company}
                            error={errors.company}
                            onChange={handleChange}
                          />

                          <FormControl
                            type="text"
                            name="title"
                            title="Title"
                            required={true}
                            placeholder="eg CEO"
                            value={values.title}
                            error={errors.title}
                            onChange={handleChange}
                          />

                          <FormControl
                            type="email"
                            name="email"
                            title="Email"
                            required={true}
                            placeholder="eg john@equidefi.com"
                            value={values.email}
                            error={errors.email}
                            onChange={handleChange}
                          />

                          <FormControl
                            type="phone"
                            name="phone"
                            title="Phone"
                            value={values.phone}
                            error={errors.phone}
                            onChange={handleChange}
                          />
                        </>
                      )}
                      <FormControl
                        type="checkbox"
                        name="portal_access"
                        label="Allow portal access?"
                        value={values.portal_access}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("role", "");
                        }}
                      />

                      {values.portal_access && (
                        <div className="mt-2">
                          <FormControl
                            type="select"
                            name="portal_role"
                            title="Access Level"
                            required={true}
                            options={roles}
                            value={selectedRole}
                            error={errors.portal_role}
                            onChange={handleChange}
                          />
                        </div>
                      )}
                      <hr />
                      <div className="mt-3">
                        <button className="btn btn-eq-primary" type="submit">
                          Continue
                        </button>
                        <button
                          className="btn btn-link ms-2"
                          type="button"
                          onClick={() => showModal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </Spinner>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Contact;
