/* eslint-disable react-hooks/exhaustive-deps */
import
  {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    Code,
    SimpleGrid,
    useToast,
  } from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";
import { useParams } from "react-router-dom";

import { Heading, Input, Paper } from "@equidefi/ui";
import { useFormik } from "formik";
import NumberFormat from "react-number-format";
import { useOffering, useOfferingUpdate } from "../../hooks/useOfferings";
import Header from "../../layouts/header";

const OfferingSlugForm = ({ offering, isLoading = false, onSubmit }) => {
  const form = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
    initialValues: {
      slug: offering?.slug,
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <Input
        isRequired
        label="Slug"
        {...form.getFieldProps("slug")}
        placeholder="e.g. equidefi-01"
      ></Input>
      <Button type="submit" mt="2" isLoading={isLoading}>
        Update
      </Button>
    </form>
  );
};

const OfferingFeesForm = ({ offering, isLoading = false, onSubmit }) => {
  const form = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
    initialValues: {
      stripe_account_id: offering?.stripe_account_id,
      ach_fee: offering?.ach_fee,
      credit_card_fee: offering?.credit_card_fee,
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <Input
        isRequired
        label="Stripe Connect Account ID"
        {...form.getFieldProps("stripe_account_id")}
        placeholder="e.g. acct_12093812398"
      />
      <Input
        label="ACH Fee"
        as={NumberFormat}
        isRequired
        allowNegative={false}
        onValueChange={({ value }) => {
          form.setFieldValue("ach_fee", value);
        }}
        decimalScale={3}
        {...form.getFieldProps("ach_fee")}
      />
      <Input
        label="Credit Card Fee"
        as={NumberFormat}
        isRequired
        allowNegative={false}
        onValueChange={({ value }) => {
          form.setFieldValue("credit_card_fee", value);
        }}
        decimalScale={3}
        {...form.getFieldProps("credit_card_fee")}
      />
      <Button type="submit" mt="2" isLoading={isLoading}>
        Update
      </Button>
    </form>
  );
};

const OfferingAdmin = () => {
  const { id } = useParams();
  const toast = useToast({ status: "success" });

  const { data: offering } = useOffering(id);
  const offeringUpdate = useOfferingUpdate(id);

  const handleUpdate = async (data) => {
    try {
      await offeringUpdate.mutateAsync(data);
      toast({ description: "Successfully updated the offering." });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast({ status: "error", description: "Something went wrong" });
    }
  };

  return (
    <>
      <Header name="Admin" title={offering?.name} />
      <div className="container-fluid">
        <Alert status="warning" variant="left-accent" mt="4">
          <AlertIcon />
          <div>
            <AlertTitle>This offering is active!</AlertTitle>
            <AlertDescription>
              Do not change these values unless you are 100% sure you know what
              you are doing... Marc...
            </AlertDescription>
          </div>
        </Alert>
        <SimpleGrid columns={{ base: 1, lg: 2 }} gap="4" mt="4">
          <div>
            <Paper p="4">
              <Heading>Offering Slug</Heading>
              <OfferingSlugForm
                offering={offering}
                isLoading={offeringUpdate.isLoading}
                onSubmit={handleUpdate}
              />
            </Paper>
          </div>
          <div>
            <Paper p="4">
              <Heading>Stripe and Fees</Heading>
              <Alert mb="3">
                <AlertDescription>
                  These values need to be entered as multipliers. For example, a
                  0.6% fee should be entered as{" "}
                  <Code colorScheme="gray">0.006</Code>.
                </AlertDescription>
              </Alert>
              <OfferingFeesForm
                offering={offering}
                isLoading={offeringUpdate.isLoading}
                onSubmit={handleUpdate}
              />
            </Paper>
          </div>
        </SimpleGrid>
      </div>
    </>
  );
};

export default OfferingAdmin;
