import { ApiClient } from "./ApiClient";

type ManualPaymentData = {
  investment_id: string;
  amount: number | string;
  reference_number: string;
  description?: string;
};

class PaymentClient extends ApiClient {
  async addManual(data: ManualPaymentData) {
    const response = await this.post(`/v2/payments/manual`, data);
    return response.data;
  }

  async addManualRefund(data: ManualPaymentData) {
    const response = await this.post("/v2/payments/manual/refund", data);

    return response.data;
  }
}

export default PaymentClient;
