/* eslint-disable react-hooks/exhaustive-deps */
import { Button, useClipboard, useToast } from "@chakra-ui/react";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { Icon } from "@equidefi/ui/icon";
import React, { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import Spinner from "../../components/spinner";
import { isAdmin, isOfferingEditor, isOfferingOwner } from "../../helpers";
import { searchBroker } from "../../hooks/useBrokers";
import
  {
    addOfferingBroker,
    removeOfferingBroker,
    useOffering,
    useOfferingBrokers,
  } from "../../hooks/useOfferings";
import Header from "../../layouts/header";

const BrokerURL = ({ url }) => {
  const shareUrl = useClipboard(url);

  return (
    <Button
      colorScheme="gray"
      color="gray.500"
      variant="ghost"
      size="sm"
      leftIcon={<Icon.Clipboard size="1em" />}
      onClick={shareUrl.onCopy}
    >
      {shareUrl.hasCopied ? "Copied!" : "Share URL"}
    </Button>
  );
};

const Brokers = () => {
  const { id } = useParams();
  const { data: me } = useCurrentUser();

  const { data: offering } = useOffering(id);
  const { data: brokers, isLoading: spinner, refetch } = useOfferingBrokers(id);
  const [selectedBroker, setSelectedBroker] = useState();

  const [modal, showModal] = useState(false);
  const toast = useToast();

  const handleDelete = async (broker) => {
    try {
      await removeOfferingBroker(id, broker.id);
      await refetch();
      toast({
        status: "success",
        description: "The broker was removed from the offering."
      });
    } catch (error) {
      console.error(error);
    }
  };

  const canEdit = useMemo(
    () =>
      (isAdmin(me) ||
        isOfferingEditor(me, offering?.id) ||
        isOfferingOwner(me, offering?.id)) && [
        me,
        offering?.id,
        offering?.status,
      ]
  );

  return (
    <>
      <Header id={id} name="Offering Brokers" title={offering?.name}>
        {canEdit && (
          <button
            className="btn btn-eq-primary"
            onClick={() => {
              showModal(true);
            }}
          >
            Add Broker
          </button>
        )}
      </Header>
      <div className="container-fluid">
        <Spinner show={!offering ?? spinner}>
          <div className="card">
            <div className="table-responsive">
              <table className="table table-nowrap">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Company</th>
                    <th>Tracking Code</th>
                    <th>CRD Number</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {brokers?.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="align-baseline">
                          <div>
                            {item.first_name} {item.last_name}
                          </div>
                        </td>
                        <td className="align-baseline">
                          <div className="text-muted">{item.company}</div>
                        </td>
                        <td className="align-baseline">{item.code}</td>
                        <td className="align-baseline">{item.crd_number}</td>
                        <td className="text-end align-baseline">
                          <BrokerURL url={item.share_url} />
                          {canEdit && (
                            <button
                              className="btn btn-sm btn-light ms-2"
                              onClick={() => handleDelete(item)}
                            >
                              <i className="fe fe-trash" />
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Spinner>

        <Modal
          show={modal}
          onHide={() => showModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="my-modal"
        >
          <Modal.Body>
            <h1>Add Broker to Offering</h1>
            <AsyncSelect
              className="mt-2"
              type="select"
              name="type"
              title="Select a Broker"
              isSearchable={true}
              required={true}
              loadOptions={searchBroker}
              value={selectedBroker}
              onChange={setSelectedBroker}
            />
            <hr />
            <div className="mt-3">
              <button
                className="btn btn-eq-primary"
                type="submit"
                onClick={async () => {
                  try {
                    await addOfferingBroker(id, selectedBroker.value.id);
                    toast({
                      status: "success",
                      description: "The broker was added for the offering."
                    });
                    showModal(false);
                    setSelectedBroker(null);
                    await refetch();
                  } catch (error) {
                    console.log(error);
                    toast({
                      status: "error",
                      description: "Failed to add broker, please check!"
                    });
                  }
                }}
              >
                Save
              </button>
              <button
                className="btn btn-link ms-2"
                type="button"
                onClick={() => showModal(false)}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Brokers;
