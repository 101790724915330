import { Box, Circle, Flex, useRadio } from "@chakra-ui/react";

export function Radio(props: any) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Flex
        {...checkbox}
        cursor="pointer"
        bg="white"
        width="100%"
        borderColor="white"
        borderWidth="1px"
        borderRadius="5px"
        fontSize="0.9rem"
        color="#00090"
        direction="row"
        align="center"
        boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);"
        _checked={{
          bg: "#DBDEE0",
          borderColor: "#102633",
        }}
        p="10px"
        mb="10px"
      >
        <Circle
          {...checkbox}
          size="20px"
          bg="white"
          mr="5px"
          borderColor="#E2E8F0"
          borderWidth="2px"
          _checked={{
            borderWidth: "6px",
            borderColor: "#3182CE",
          }}
        />
        {props.children}
      </Flex>
    </Box>
  );
}
