import { Button, useToast } from "@chakra-ui/react";
import { Formik } from "formik";
import { useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";

import { PhoneField } from "@equidefi/portals/components/Auth";
import
  {
    useCurrentUser,
    useUpdateCurrentUser,
  } from "@equidefi/portals/hooks/useUsers";
import { Input } from "@equidefi/ui";

import Header from "../../layouts/header";

const MyProfile = (props) => {
  const toast = useToast();

  const { data: user, refetch } = useCurrentUser();
  const updateUser = useUpdateCurrentUser();
  const [spinner, setSpinner] = useState(false);

  const profileSchema = Yup.object().shape({
    first_name: Yup.string().nullable().required("Please enter your first name"),
    middle_name: Yup.string().nullable(),
    last_name: Yup.string().nullable().required("Please enter your last name"),
    company: Yup.string().nullable().required("Required"),
    title: Yup.string().nullable().required("Required"),
    phone: Yup.string().nullable().required("Required").test({
      name: "phone",
      message: "Must be a valid phone number",
      test: (value) => (!!value ? isValidPhoneNumber(value) : false),
    }),
  });

  const handleSubmit = async (form) => {
    try {
      setSpinner(true);

      const request = {
        first_name: form.first_name,
        middle_name: form.middle_name,
        last_name: form.last_name,
        phone: form.phone,
        company: form.company,
        title: form.title,
      };

      await updateUser.mutateAsync(request);

      toast({
        status: "success",
        description: `Your profile has been updated successfully`,
      });
      refetch();
    } catch (e) {
      console.error("error: ", e);
      toast({
        status: "error",
        description: e.response.data.errors.join(". "),
      });
    } finally {
      setSpinner(false);
    }
  };

  return (
    <>
      <Header name="My Profile"></Header>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-7 col-lg-6">
            <div className="card">
              <div className="card-body">
                <Formik
                  initialValues={user}
                  validationSchema={profileSchema}
                  enableReinitialize
                  onSubmit={handleSubmit}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  {({
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    getFieldProps,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="form-group col">
                          <Input
                            label="First Name"
                            isRequired
                            isInvalid={errors.first_name && touched.first_name}
                            error={errors.first_name}
                            placeholder="e.g. John"
                            {...getFieldProps("first_name")}
                          />
                        </div>
                        <div className="form-group col">
                          <Input
                            label="Middle Name"
                            isInvalid={
                              errors.middle_name && touched.middle_name
                            }
                            error={errors.middle_name}
                            placeholder="e.g. David"
                            {...getFieldProps("middle_name")}
                          />
                        </div>
                        <div className="form-group col">
                          <Input
                            label="Last Name"
                            isRequired
                            isInvalid={errors.last_name && touched.last_name}
                            error={errors.last_name}
                            placeholder="e.g. Smith"
                            {...getFieldProps("last_name")}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <Input
                          label="Title"
                          isRequired
                          isInvalid={errors.title && touched.title}
                          error={errors.title}
                          placeholder="e.g. Investor"
                          {...getFieldProps("title")}
                        />
                      </div>
                      <div className="form-group">
                        <Input
                          label="Company"
                          isRequired
                          isInvalid={errors.company && touched.company}
                          error={errors.company}
                          placeholder="e.g. Investor"
                          {...getFieldProps("company")}
                        />
                      </div>
                      <div className="form-group">
                        <Input
                          label="Email"
                          isDisabled
                          value={values?.email}
                          placeholder="e.g. address@example.com"
                        />
                      </div>
                      <div className="form-group">
                        <PhoneField
                          error={errors?.phone}
                          setFieldValue={setFieldValue}
                          isRequired
                          {...getFieldProps("phone")}
                        />
                      </div>

                      <Button
                        isLoading={spinner}
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Update Profile
                      </Button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-6 d-none d-lg-flex">
            <img
              src="/img/profile.svg"
              className="img-fluid mb-6 mb-md-0 aos-init aos-animate"
              alt="..."
              data-aos="fade-up"
              data-aos-delay="100"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
