import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Formik } from "formik";

import FormControl from "../../../components/formControl";
import Spinner from "../../../components/spinner";
import { updateOffering } from "../../../helpers";
import { useOffering } from "../../../hooks/useOfferings";

import { EXEMPTIONS } from "../../../constants";

const WizardExemptions = (props) => {
  const history = useHistory();
  const [spinner, showSpinner] = useState(false);

  const { id } = useParams();
  const { data: offering, refetch } = useOffering(id);

  const handleSubmit = async (data) => {
    showSpinner(true);
    data.investor_minimum_units =
      data.investor_minimum_amount / data.unit_price;
    await updateOffering(offering.id, data);
    refetch();
    showSpinner(false);
    history.push(`/offering/${offering.id}/wizard/documents`);
  };

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <h2>Exemption Type</h2>
      <hr />

      <Spinner show={spinner}>
        <Formik
          enableReinitialize={true}
          initialValues={offering}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleChange, handleSubmit, setFieldValue, values }) => {
            const primary = values.exemption.primary
              ? EXEMPTIONS.filter(
                  (x) => x.value === values.exemption.primary
                )[0]
              : {};
            const secondary =
              primary && primary.items && values.exemption.secondary
                ? primary.items.filter(
                    (x) => x.value === values.exemption.secondary
                  )[0]
                : {};
            const tertiary =
              secondary && secondary.items && values.exemption.tertiary
                ? secondary.items.filter(
                    (x) => x.value === values.exemption.tertiary
                  )[0]
                : {};

            return (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl
                  type="select"
                  name="exemption[primary]"
                  title="Primary Exemption"
                  options={EXEMPTIONS}
                  value={primary}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("exemption[secondary]", "");
                    setFieldValue("exemption[tertiary]", "");
                  }}
                />

                {primary && primary.items && (
                  <FormControl
                    type="select"
                    name="exemption[secondary]"
                    title="Secondary Exemption"
                    options={primary.items}
                    value={secondary}
                    onChange={handleChange}
                  />
                )}

                {secondary && secondary.items && (
                  <FormControl
                    type="select"
                    name="exemption[tertiary]"
                    title="Tertiary Exemption"
                    options={secondary.items}
                    value={tertiary}
                    onChange={handleChange}
                  />
                )}

                <hr />
                <button className="btn btn-eq-primary" type="submit">
                  Save &amp; Continue
                </button>
              </form>
            );
          }}
        </Formik>
      </Spinner>
    </>
  );
};

export default WizardExemptions;
