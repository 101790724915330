import { Link, Text } from "@chakra-ui/react";
import { useState } from "react";

export const ReadMore = ({ text, maxLength }) => {
    const [readMore, setReadMore] = useState(false);
    const linkText = readMore ? 'Read Less' : 'Read More'
    const splitText = text?.trim().length > maxLength
    return splitText
        ? (
            <>
                {text.substring(0, maxLength)}
                {readMore ? text.substring(maxLength, text.length) : '...'}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                    onClick={() => { setReadMore(!readMore) }}
                >
                    <Text mt={3} mb="0">{linkText}</Text>
                </Link>
            </>
        )
        : (<>{text}</>)
}