export const REGULATION_A = "Regulation A";
export const REGULATION_A_PLUS = "Regulation A+";
export const REGULATION_D = "Regulation D";
export const REGULATION_S = "Regulation S";
export const REGULATION_CF = "Regulation CF";
export const SECTION_4A5 = "Section 4(a)(5)";
export const SELF_UNDERWRITTEN = "Self-Underwritten";
export const RULE_504B = "Rule 504(b)";
export const RULE_506B = "Rule 506(b)";
export const RULE_506C = "Rule 506(c)";
export const TIER_1 = "Tier 1";
export const TIER_2 = "Tier 2";

export const TIERS = {
  TIER_1: TIER_1,
  TIER_2: TIER_2,
};

export const RULES = {
  RULE_504B: RULE_504B,
  RULE_506B: RULE_506B,
  RULE_506C: RULE_506C,
};

export const REGULATIONS = {
  REGULATION_A: REGULATION_A,
  REGULATION_A_PLUS: REGULATION_A_PLUS,
  REGULATION_D: REGULATION_D,
  REGULATION_S: REGULATION_S,
  REGULATION_CF: REGULATION_CF,
};

export const CROWDFUNDED_REGS = [
  REGULATION_A,
  REGULATION_A_PLUS,
  SELF_UNDERWRITTEN,
];

export const STATUS = {
  DRAFT: "Draft",
  IN_REVIEW: "In Review",
  ACTIVE: "Active",
  CLOSED: "Closed",
  TERMINATED: "Terminated",
};
