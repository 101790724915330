import {
  FormControl,
  FormErrorMessage,
  FormLabel
} from "@chakra-ui/react";
import clsx from "clsx";
import { FormikErrors, FormikValues } from "formik";
import React from "react";
import PhoneInput from "react-phone-number-input";
import { useCurrentUser } from "../hooks/useUsers";
import styles from "./auth.module.css";
import "./phone-number.css";

type FieldProps = {
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleBlur: React.ChangeEventHandler<HTMLInputElement>;
  errors: FormikErrors<FormikValues>;
  values: FormikValues;
  label?: string;
  classes?: {
    formGroup: string;
    input: string;
    danger: string;
    label: string;
  };
};

export const FirstName = ({
  handleChange,
  handleBlur,
  errors,
  values,
  label = "First Name",
  classes,
}: FieldProps) => {
  return (
    <div className={classes ? classes.formGroup : styles.formGroup}>
      {label ? (
        <label
          htmlFor="first_name"
          className={classes ? classes.label : styles.label}
        >
          {label}{" "}
          <span className={classes ? classes.danger : styles.danger}>*</span>
        </label>
      ) : null}
      <input
        id="first_name"
        name="first_name"
        value={values.first_name}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        className={classes ? classes.input : styles.input}
      />
      {errors?.first_name && (
        <span className={classes ? classes.danger : styles.danger}>
          {errors?.first_name as ReactNode}
        </span>
      )}
    </div>
  );
};

export const MiddleName = ({
  handleChange,
  handleBlur,
  errors,
  values,
  label = "Middle Name",
  classes,
}: FieldProps) => {
  return (
    <div className={classes ? classes.formGroup : styles.formGroup}>
      {label ? (
        <label
          htmlFor="middle_name"
          className={classes ? classes.label : styles.label}
        >
          {label}
        </label>
      ) : null}
      <input
        id="middle_name"
        name="middle_name"
        value={values.middle_name}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        className={classes ? classes.input : styles.input}
      />
      {errors?.middle_name && (
        <span className={classes ? classes.danger : styles.danger}>
          {errors?.middle_name as ReactNode}
        </span>
      )}
    </div>
  );
};

export const LastName = ({
  handleChange,
  handleBlur,
  errors,
  values,
  label = "Last Name",
  classes,
}: FieldProps) => {
  return (
    <div className={classes ? classes.formGroup : styles.formGroup}>
      {label ? (
        <label
          htmlFor="last_name"
          className={classes ? classes.label : styles.label}
        >
          {label}{" "}
          <span className={classes ? classes.danger : styles.danger}>*</span>
        </label>
      ) : null}
      <input
        id="last_name"
        name="last_name"
        value={values.last_name}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        className={classes ? classes.input : styles.input}
      />
      {errors?.last_name && (
        <span className={classes ? classes.danger : styles.danger}>
          {errors?.last_name as ReactNode}
        </span>
      )}
    </div>
  );
};

export const Title = ({
  handleChange,
  handleBlur,
  errors,
  values,
  label = "Title",
  classes,
}: FieldProps) => {
  return (
    <div className={classes ? classes.formGroup : styles.formGroup}>
      {label ? (
        <label
          htmlFor="title"
          className={classes ? classes.label : styles.label}
        >
          {label}
        </label>
      ) : null}
      <input
        id="title"
        name="title"
        value={values.title}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        className={classes ? classes.input : styles.input}
      />
      {errors?.title && (
        <span className={classes ? classes.danger : styles.danger}>
          {errors?.title as ReactNode}
        </span>
      )}
    </div>
  );
};

export const Company = ({
  handleChange,
  handleBlur,
  errors,
  values,
  label = "Company",
  classes,
}: FieldProps) => {
  return (
    <div className={classes ? classes.formGroup : styles.formGroup}>
      {label ? (
        <label
          htmlFor="company"
          className={classes ? classes.label : styles.label}
        >
          {label}
        </label>
      ) : null}
      <input
        id="company"
        name="company"
        value={values.company}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        className={classes ? classes.input : styles.input}
      />
      {errors?.company && (
        <span className={classes ? classes.danger : styles.danger}>
          {errors?.company as ReactNode}
        </span>
      )}
    </div>
  );
};

export const Password = ({
  handleChange,
  handleBlur,
  errors,
  values,
  label = "Password",
  classes,
}: FieldProps) => {
  return (
    <div className={classes ? classes.formGroup : styles.formGroup}>
      {label ? (
        <label
          htmlFor="Password"
          className={classes ? classes.label : styles.label}
        >
          {label}
        </label>
      ) : null}
      <input
        id="password"
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        type="password"
        className={classes ? classes.input : styles.input}
      />
      {errors?.password && (
        <span className={classes ? classes.danger : styles.danger}>
          {errors?.password as ReactNode}
        </span>
      )}
    </div>
  );
};

export const ConfirmPassword = ({
  handleChange,
  handleBlur,
  errors,
  values,
  label = "Confirm Password",
  classes,
}: FieldProps) => {
  return (
    <div className={classes ? classes.formGroup : styles.formGroup}>
      {label ? (
        <label
          htmlFor="Confirm Password"
          className={classes ? classes.label : styles.label}
        >
          {label}
        </label>
      ) : null}
      <input
        id="confirm_password"
        name="confirm_password"
        value={values.confirm_password}
        onChange={handleChange}
        onBlur={handleBlur}
        type="password"
        className={classes ? classes.input : styles.input}
      />
      {errors?.confirm_password && (
        <span className={classes ? classes.danger : styles.danger}>
          {errors?.confirm_password as ReactNode}
        </span>
      )}
    </div>
  );
};

interface EmailProps extends FieldProps {
  getCurrentUser: boolean;
  disabled?: boolean;
}

export const Email = ({
  handleChange,
  handleBlur,
  errors,
  values,
  label = "Email",
  classes,
  disabled = false,
  getCurrentUser = false,
}: EmailProps) => {
  const { data: me } = useCurrentUser();

  return (
    <div className={clsx(classes ? classes.formGroup : styles.formGroup)}>
      {label ? (
        <label
          htmlFor="email"
          className={classes ? classes.label : styles.label}
        >
          {label}{" "}
          <span className={classes ? classes.danger : styles.danger}>*</span>
        </label>
      ) : null}
      <input
        id="email"
        name="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="user@example.com"
        disabled={disabled || (getCurrentUser && me?.id)}
        type="email"
        className={classes ? classes.input : styles.input}
      />
      {errors?.email && (
        <span className={classes ? classes.danger : styles.danger}>
          {errors?.email as ReactNode}
        </span>
      )}
    </div>
  );
};

interface PhoneProps extends FieldProps {
  setFieldValue: Function;
  error: string;
  value: string;
  isRequired: boolean;
}

export const PhoneField = ({
  error,
  value,
  setFieldValue,
  label = "Mobile Phone",
  handleBlur,
  isRequired = true,
}: PhoneProps) => {
  return (
    <FormControl isRequired={isRequired} isInvalid={!!error} mt="1" mb="2">
      <FormLabel fontSize="0.9em" p="0" mt="0" mb="0.3rem">
        {label}
      </FormLabel>
      <PhoneInput
        name="phone"
        defaultCountry="US"
        placeholder="Enter phone number"
        value={value}
        onChange={(value) => setFieldValue("phone", value)}
        onBlur={handleBlur}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
