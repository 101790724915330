import React from 'react';
import {Page, Text, View, Document, Image, PDFViewer} from '@react-pdf/renderer';
import { CurrencyFormatter, NumberFormatter } from "../../constants/format";
import {Table, TR, TH, TD} from "@ag-media/react-pdf-table";
import camelcase from "camelcase";
import moment from "moment";
import {styles} from './style';


const EquiDeFi = () => (
  <View style={styles.logo}>
    <Image src="/img/logo-high-res.png" style={styles.image} />
    <Text style={styles.invoice}>INVOICE</Text>
  </View>
)

const Issuer = ({name, address, phone}) => {
  return (
    <View style={styles.issuer}>
      <Text style={styles.billable}>Billable to:</Text>
      <Text style={styles.name}>{name}</Text>
      <Text style={styles.address}>{address.line_1}, {address.city}, {camelcase(address.state, {pascalCase: true})} {address.postal_code}</Text>
      <Text style={styles.phone}>{phone}</Text>
    </View>
  )
}

const DueDate = ({createDate}) => {
  return (
    <View style={styles.due}>
      <Text style={styles.dueLabel}>Due Date:</Text>
      <Text style={styles.dueDate}>{moment(createDate).format("ll")}</Text>
    </View>
  )
}

const Row = ({children}) => {
  return (
    <View style={styles.row}>
      {children}
    </View>
  )
}

const Bill = ({charges, totalAmount}) => {
  return (
    <View style={styles.bill}>
      <Table
        style={styles.table.root}
        weightings={[0.6, 0.2, 0.2, 0.2, 0.2]}
        // tdStyle={styles.table.cell}
        // trStyle={styles.table.header}
      >
          <TH textAlign={"left"}>
            <TD style={styles.table.header}>
                Description
            </TD>
            <TD style={styles.table.header}>
                Cost
            </TD>
            <TD style={styles.table.header}>
                Qty
            </TD>
            <TD style={[styles.table.header]}>
                Sub-total
            </TD>
          </TH>
          {charges.map((charge, index) => (
            <TR key={index}>
                <TD style={styles.table.cell}>{charge.description}</TD>
                <TD style={styles.table.cell}>{CurrencyFormatter.format(charge.unit_cost)}</TD>
                <TD style={styles.table.cell}>{NumberFormatter.format(charge.qty)}</TD>
                <TD style={styles.table.cell}>{CurrencyFormatter.format(charge.qty * charge.unit_cost)}</TD>
            </TR>
          ))}
          <TR>
            <TD style={styles.table.cellNoBorder}></TD>
            <TD style={styles.table.cellNoBorder}></TD>
            <TD style={[styles.table.total, {justifyContent: 'flex-end'}]}>Total:</TD>
            <TD style={styles.table.total}>{CurrencyFormatter.format(totalAmount)}</TD>
          </TR>
        </Table>
    </View>
  )
}

export const Invoice = ({invoice}) => {

  return (
    <Document style={{flex: 1, width: '100%', height: '500px'}}>
      <Page size="A4" style={styles.page}>
        <View style={styles.wrapper}>
          <EquiDeFi />
          <Row>
            <Issuer address={invoice.address} name={invoice.name} phone={invoice.phone} />
            <DueDate createDate={invoice.create_date} />
          </Row>
          <Bill charges={invoice.charges} totalAmount={invoice.total_amount} />
        </View>
      </Page>
    </Document>
  )

};

