/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link, useHistory } from "react-router-dom";

import { Button } from "@chakra-ui/react";

import Loader from "../../components/spinner";
import Header from "../../layouts/header";

import { useBrokerList } from "../../hooks/useBrokers";

const Brokers = (props) => {
  const history = useHistory();

  const { data: brokers, isLoading: spinner } = useBrokerList({
    refetchOnMount: true,
  });

  return (
    <>
      <Header name="Brokers">
        <Button as={Link} to="/brokers/create">
          New Broker
        </Button>
      </Header>

      <div className="container-fluid">

        <Loader show={spinner}>
          <div className="card">
            <div className="table-responsive">
              <table className="table table-hover table-nowrap card-table ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Company</th>
                    <th>Tracking Code</th>
                    <th>CRD Number</th>
                  </tr>
                </thead>
                <tbody>
                  {brokers?.map((broker, index) => {
                    return (
                      <tr
                        key={broker.id}
                        onClick={() =>
                          history.push(`/brokers/${broker.id}/broker`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <td className="align-baseline">{broker.first_name} {broker.last_name}</td>
                        <td className="align-baseline">{broker.company}</td>
                        <td className="align-baseline">{broker.code}</td>
                        <td className="align-baseline">{broker.crd_number}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Loader>
      </div>
    </>
  );
};

export default Brokers;
