import { Formik } from 'formik';
import { Link, useParams } from 'react-router-dom';

import { useToast } from "@chakra-ui/react";

import FormControl from '../../components/formControl';
import Spinner from '../../components/spinner';
import { useSetNewPassword } from "../../../../investor/src/hooks/useUsers";
import { resetFormSchema } from "../../constants/forms";

const initialValues = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = (props) => {
  document.title = "Reset Password | Powered by EquiDeFi";
  const toast = useToast({ status: "info" });
  const params = useParams();
  const setNewPassword = useSetNewPassword();

  const handleResetPassword = async ({ password }) => {
    try {
      await setNewPassword.mutateAsync({
        password,
        token: params.token,
        userId: params.id,
      });
      toast({
        description: "Your password has been updated, please login to continue",
      });
      props.history.push("/");
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      toast({
        status: "error",
        description:
          errorMessage ||
          "An error occured while trying to process your request.",
      });
    };
  };

  return (
    <div className="container-fluid">
      <div className="row vh-100 justify-content-center">
        <div className="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">
          <img
            src="/img/logo.svg"
            width="200px"
            alt="logo"
            className="d-block m-auto mb-5"
          />
          <h1 className="display-4 text-center mb-2">Reset Password</h1>
          <p className="text-muted text-center mb-5">
            Enter a secure password for your account
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={resetFormSchema}
            onSubmit={handleResetPassword}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleChange, handleSubmit, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl
                  type="password"
                  name="password"
                  title="New Password"
                  required={true}
                  value={values.password}
                  error={errors.password}
                  onChange={handleChange}
                />

                <FormControl
                  type="password"
                  name="confirmPassword"
                  title="Confirm Password"
                  required={true}
                  value={values.confirmPassword}
                  error={errors.confirmPassword}
                  onChange={handleChange}
                />

                <Spinner show={setNewPassword.isLoading}>
                  <button type="submit" className="btn w-100 btn-eq-primary">
                    Reset Password
                  </button>
                </Spinner>

                <div className="small text-center text-muted mt-3">
                  Remember your password? <Link to="/">Sign In</Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
          <div
            className="bg-cover h-100 min-vh-100 mt-n1 me-n3"
            style={{ backgroundImage: "url(/img/login.jpg)" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
