import
  {
    Button,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast,
  } from "@chakra-ui/react";
import { useMemo } from "react";
import * as Yup from "yup";

import { Select } from "@equidefi/ui";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { useFormik } from "formik";
import { MESSAGE } from "../../constants/forms";
import { isAdmin } from "../../helpers";
import { useIssuerUsers } from "../../hooks/useIssuers";
import
  {
    useOfferingFull,
    useOfferingSignatoryUpdate,
  } from "../../hooks/useOfferings";

const OfferingSignatoryButton = ({ offering, children }) => {
  const modal = useDisclosure();
  const { data: users, isLoading } = useIssuerUsers(offering?.issuer_id);
  const updateSignatory = useOfferingSignatoryUpdate(offering?.id);
  const { refetch } = useOfferingFull(offering?.id);

  const { data: user } = useCurrentUser();

  const toast = useToast();

  const handleSubmit = async (data) => {
    try {
      await updateSignatory.mutateAsync({ signatoryId: data.signatory_id });
      refetch();
      toast({
        status: "info",
        description: "Signatory has been updated",
      });
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description: "Sorry, we could not update the signatory",
      });
    }
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: { signatory_id: offering?.signatory_id },
    validationSchema: Yup.object().shape({
      signatory_id: Yup.string()
        .required(MESSAGE.REQUIRED("signatory"))
        .nullable(),
    }),
    validateOnMount: false,
    onSubmit: handleSubmit,
  });

  const options = useMemo(
    () =>
      users?.map((user) => ({
        label: user.full_name,
        value: user.id,
      })) ?? [],
    [users]
  );

  const value = options?.find((option) => option.value === form?.signatory_id);
  const defaultValue = options?.find(
    (option) => option.value === offering?.signatory_id
  ) ?? {
    value: undefined,
    label: "No signatory selected",
  };

  const handleChange = (data) => {
    if (!data) return form.setFieldValue("signatory_id", null);
    return form.setFieldValue("signatory_id", data.value);
  };

  if (!isAdmin(user)) {
    return null;
  }

  return (
    <>
      <Button variant="outline" size="xs" onClick={modal.onToggle}>
        {children}
      </Button>
      <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <form onSubmit={form.handleSubmit}>
            <ModalHeader>Choose Signatory</ModalHeader>
            <ModalBody>
              <Select
                {...form.getFieldProps("signatory_id")}
                label="Signatory"
                options={options}
                value={value}
                defaultValue={defaultValue}
                onChange={handleChange}
                noOptionsMessage={() => "No users exist on this issuer yet"}
                isRequired
                isInvalid={form.errors.signatory_id}
                error={form.errors.signatory_id}
                isClearable
                isLoading={isLoading}
                components={{
                  IndicatorSeparator: null,
                }}
              />
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size="sm">
                <Button
                  variant="ghost"
                  colorScheme="gray"
                  onClick={modal.onClose}
                >
                  Cancel
                </Button>
                <Button
                  isDisabled={!form.isValid}
                  isLoading={updateSignatory.isLoading}
                  type="submit"
                >
                  Submit
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OfferingSignatoryButton;
