/* eslint-disable react-hooks/exhaustive-deps */
import
  {
    ButtonGroup,
    Editable,
    EditableInput,
    EditablePreview,
    EditableTextarea,
    Flex,
    IconButton,
    Spacer,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Tr,
    useEditableControls,
  } from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";
function EditableControls() {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return (
    <ButtonGroup as={Flex} size="xs" colorScheme="gray">
      {isEditing ? (
        <>
          <IconButton
            icon={<Icon.Check size="1em" />}
            {...getSubmitButtonProps()}
          />
          <IconButton
            icon={<Icon.XCircle size="1em" />}
            {...getCancelButtonProps()}
          />
        </>
      ) : (
        <IconButton
          icon={<Icon.Edit3 size="1em" />}
          {...getEditButtonProps()}
        />
      )}
    </ButtonGroup>
  );
}

const SettingsGroup = ({
  title,
  subtitle,
  settings,
  adjustName = (name) => name,
  onEdit = () => {},
  isEditable = false,
}) => {
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="mb-0">{title}</h3>
      </div>

      <TableContainer>
        <Table variant="simple">
          {subtitle && <TableCaption mb="3">{subtitle}</TableCaption>}
          <Tbody>
            {settings?.map((item) => {
              const EditableInputType =
                item.type === "textarea" ? EditableTextarea : EditableInput;

              return (
                <Tr key={item.id}>
                  <Td w="33%" borderBottomColor="gray.100">
                    {adjustName(item.name)}
                  </Td>
                  <Editable
                    as={Td}
                    submitOnBlur={false}
                    onSubmit={(value) => {
                      onEdit(item.id, adjustName(item.name), value);
                    }}
                    isDisabled={!isEditable}
                    defaultValue={item.value}
                    borderBottomColor="gray.100"
                    minW="67%"
                    maxW="67%"
                  >
                    <Flex>
                      <EditableInputType rows="4" flexBasis="80%" />
                      <EditablePreview />

                      <Spacer />
                      {isEditable && <EditableControls />}
                    </Flex>
                  </Editable>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SettingsGroup;
