import { Constants } from "..";
import { STATUS } from "../constants/offerings";

const { CROWDFUNDED_REGS, REGULATIONS, RULES } = Constants.Offering;

export const offeringIsCrowdfunded = (offering) => {
  if (offeringIsRegD(offering) && offeringIsRule506c(offering)) {
    return true;
  }

  return CROWDFUNDED_REGS.includes(offering?.exemption?.primary);
};

export const offeringIsRegD = (offering) => {
  return offering?.exemption?.primary === REGULATIONS.REGULATION_D;
};

export const offeringIsRegA = (offering) => {
  return offering?.exemption?.primary === REGULATIONS.REGULATION_A;
};

export const offeringIsRule506c = (offering) => {
  return (
    offeringIsRegD(offering) &&
    offering?.exemption?.secondary === RULES.RULE_506C
  );
};

export const offeringIsRule506b = (offering) => {
  return offering?.exemption?.secondary === RULES.RULE_506B;
};

export const offeringIsActive = (offering) => {
  return offering?.status === STATUS.ACTIVE;
};

export const offeringIsTerminated = (offering) => {
  return offering?.status === STATUS.TERMINATED;
};

export const offeringIsClosed = (offering) => {
  return offering?.status === STATUS.CLOSED;
};

export const offeringIsCompleted = (offering) => {
  return offeringIsClosed(offering) || offeringIsTerminated(offering);
};
