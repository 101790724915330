import React from "react";
import { Link } from "react-router-dom";

const Header = (props) => {
  document.title = `${props.name} - EquiDeFi`;

  const { backTo = null } = props;

  const [backToLink, backToText] = backTo ?? [];

  return (
    <>
      <nav className="header">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row align-items-center">
              <div className="col">
                {backTo ? (
                  <Link className="text-gray-600 d-inline-flex lh-1 align-items-center gap-2 mb-2" to={backToLink}>
                    <i className="fe fe-arrow-left" />
                    {backToText}
                  </Link>
                ) : (
                  props?.title && (
                    <h5 className="text-muted text-capitalize">
                      {props?.title}
                    </h5>
                  )
                )}
                <h1 className="header-title">
                  {props?.name} {props?.badge}
                </h1>
              </div>
              <div className="col-auto">{props.children}</div>
              <div className="mt-1 text-muted ms-0 tooltipContent">
                <i className={props?.icon} /> {props?.tooltipContent}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
