/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import moment from "moment";
import swal from "sweetalert";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import Spinner from "../../components/spinner";
import { documentCategories } from "../../constants";
import
  {
    createOfferingDocument,
    deleteOfferingDocument,
    getOfferingById,
    isAdmin,
    isOfferingEditor,
    isOfferingOwner,
    upload,
  } from "../../helpers";
import Header from "../../layouts/header";

const Documents = (props) => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const offering = useSelector((state) => state.offering);
  const { data: me } = useCurrentUser();

  const [isValid, setIsValid] = useState(false);
  const [modal, showModal] = useState(false);
  const [category, setCategory] = useState("");
  const [spinner, showSpinner] = useState(false);
  const [dropdownOther, setDropdownOther] = useState("");

  useEffect(() => {
    showSpinner(true);
    getOfferingById(id).then((offering) => {
      dispatch({ type: "offering", payload: offering });
      showSpinner(false);
    });
  }, []);

  const handleDelete = (document) => {
    swal({
      title: `Remove Document`,
      text: `Are you sure you want to remove '${document.type}'?`,
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        showSpinner(true);
        deleteOfferingDocument(offering.id, document.id).then((_) => {
          toast.success(`${document.type} has been removed.`);
          getOfferingById(offering.id).then((offering) => {
            dispatch({ type: "offering", payload: offering });
            showSpinner(false);
          });
        });
      }
    });
  };

  const handleChange = (e) => {
    let value = e.target.value.trim();
    if (value) {
      setDropdownOther(e.target.value);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      showSpinner(true);
      acceptedFiles.forEach((file) => {
        let data = new FormData();
        data.append("file", file);

        upload(data).then((file) => {
          let document = {
            name: category,
            type: category !== "Other" ? category : dropdownOther,
            document_url: file.url,
          };

          createOfferingDocument(offering.id, document).then((_) => {
            setCategory("");
            getOfferingById(offering.id).then((offering) => {
              dispatch({ type: "offering", payload: offering });
              showSpinner(false);
              showModal(false);
            });
          });
        });
      });
    },
  });

  const canEdit = useMemo(
    () =>
      isAdmin(me) ||
      ((isOfferingEditor(me, offering?.id) ||
        isOfferingOwner(me, offering?.id)) &&
        ["In Review", "Draft"].includes(offering?.status)),
    [me, offering?.id, offering?.status]
  );

  return (
    <>
      <Header
        id={id}
        name="Investor Package"
        iconName="fe fe-info ms-2"
        title={offering.name}
        tooltipContent="Please use the upload button to upload PDF files of your offering documents for investor review. In the next step, you can upload only the documents investors will execute."
      >
        {canEdit && (
          <button
            className="btn btn-eq-primary"
            onClick={() => showModal(true)}
          >
            Upload Documents
          </button>
        )}
      </Header>
      <Spinner show={spinner}>
        <div className="container-fluid">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-nowrap table-hover card-table ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Uploaded On</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {offering.documents &&
                    offering.documents.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-start align-baseline">
                            {item.type}
                          </td>
                          <td className="align-baseline">
                            {item.create_date &&
                              moment(item.create_date).format("ll")}
                          </td>
                          <td className="text-end align-baseline">
                            <a
                              className="btn btn-sm btn-light"
                              href={item.document_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fe fe-download" />
                            </a>
                            {canEdit && (
                              <button
                                className="btn btn-sm btn-light ms-1"
                                onClick={() => {
                                  handleDelete(item);
                                }}
                              >
                                <i className="fe fe-trash" />
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>

          <Modal show={modal} onHide={() => showModal(false)}>
            <Modal.Body>
              <h2 className="mb-0">Upload Documents</h2>
              <hr />
              <Spinner show={spinner}>
                <div className="form-group">
                  <label className="mb-2">
                    Category<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    as="select"
                    name="document.primary"
                    defaultValue={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option hidden value="" disabled>
                      Select Category
                    </option>
                    {documentCategories.map((type, index) => {
                      return (
                        <option key={index} value={type.name}>
                          {type.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {category && category !== "Other" && (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop the respective files here, or click to
                      select files
                    </p>
                  </div>
                )}
                {category === "Other" && (
                  <>
                    <input
                      type="text"
                      name="otherCategory"
                      placeholder="Please specify category"
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                    />
                    {isValid && (
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' drop the respective files here, or click to
                          select files
                        </p>
                      </div>
                    )}
                  </>
                )}
              </Spinner>
            </Modal.Body>
          </Modal>
        </div>
      </Spinner>
    </>
  );
};

export default Documents;
