import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const MissingPage = () => {
  const { push } = useHistory();

  useEffect(() => {
    toast.error("That page does not exist");
    push("/offering");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default MissingPage;
