
export const formatPhoneNumber = (str) => {
  //format phone number according US
  //Filter only numbers from the input
  let cleaned = ("+1" + str).replace(/\D/g, "");
  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const capitalizeEveryWord = (inputString) => {
  return inputString.replace(/\b\w+/g, (match) => {
    return match.charAt(0).toUpperCase() + match.slice(1).toLowerCase();
  });
}
