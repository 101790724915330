/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from "formik";
import { isNaN } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import FormControl from "../../components/formControl";
import
  {
    default as Loader,
  } from "../../components/spinner";
import { createOfferingSchema } from "../../constants/forms";
import { IconControl, createOffering, refreshToken } from "../../helpers";
import { toMoney, toPercent } from "../../helpers/utils";
import { useIssuers } from "../../hooks/useIssuers";
import Header from "../../layouts/header";
import
  {
    BLOCKERS_OPTIONS,
    COMMON_STOCK_OPTIONS,
    FLOAT_VAULT,
    ILOC_DROPDOWN_OPTIONS,
    IN_LIEU_SUBTYPE_CLASS_OPTIONS,
    PREFERRED_SERIES_OPTIONS,
    PREFERRED_STOCK_OPTIONS,
    SAFE_DROPDOWN_OPTIONS,
    STOCK_TYPE_OPTIONS,
    UNIT_DROPDOWN1_OPTIONS,
    UNIT_DROPDOWN2_OPTIONS,
  } from "./constants";
import { calculateStockTypeName, isFloatValueAllowed } from "./helpers";

const CreateOffering = (props) => {
  const dispatch = useDispatch();
  const issuer = useSelector((state) => state.issuer);
  const { data: me } = useCurrentUser();
  const params = useParams();
  const [spinner, showSpinner] = useState(false);
  const [formData, setFormData] = useState({
    issuer: "",
    name: "",
    stock_type: "",
    sub_type: "",
    common_stock_class: "",
    preferred_sub_type: "",
    preferred_class: "",
    preferred_series: "",
    preferred_percentage: "",
    conversion_price: "",
    safe_sub_type: "",
    in_lieu_of_cash_sub_type: "",
    in_lieu_class_subtype: "",
    unit_sub_type1: "",
    unit_sub_type2: "",
    safe_pm_valuation_cap: "",
    safe_discount: "",
    safe_percentage: "",
    safe_amount: "",
    total_amount: "",
    total_units: "",
    unit_price: "",
    units_common_stock_share: "",
    units_preferred_stock_shares: "",
    units_preferred_stock_shares_2: "",
    unit_exercise_price: "",
    unit_exercise_price_2: "",
    units_warrants_percent: "",
    allow_total_units: false,
    enter_maturity_date: false,
    allow_batch: false,
    unit_type: "",
    batch_shares: "",
    batch_warrants: "",
    price_per_batch: "",
    oic_amount: "0",
    oic_percentage: "0",
    original_principle_amount: "0",
    annual_rate: "0",
    default_rate: "0",
    term: "",
    term_2: "",
    blocker: "other",
  });

  const { data: issuers } = useIssuers();
  const issuersOptions = useMemo(() => {
    return (
      issuers?.map((issuer) => ({
        value: issuer.id,
        label: issuer.name,
      })) ?? []
    );
  }, [issuers]);

  useEffect(() => {
    if (!params.id) {
      setFormData({
        ...formData,
        issuer: issuersOptions?.length === 1 ? issuersOptions?.[0]?.value : "",
      });
    }
  }, [issuersOptions]);

  useEffect(() => {
    if (params.id) {
      // fetchOfferingData();
    }
  }, [params.id]);

  const handleCreateOffering = (data, { setErrors, resetForm }) => {
    if (
      (data.stock_type !== "convertible_note" || data.stock_type === "unit") &&
      data?.unit_price > data?.total_amount &&
      !data?.allow_total_units
    ) {
      setErrors({
        unit_price: `price per share cannot be more than $${data?.total_amount}`,
      });
    } else if (
      data?.stock_type === "convertible_note" &&
      data?.conversion_price > data?.total_amount
    ) {
      setErrors({
        conversion_price: `Conversion Price cannot be more than $${data?.total_amount}`,
      });
    } else {
      showSpinner(true);

      // Since the `stock_type` is inconsistent, we should keep the original type
      // so we can set the enum value properly in the API.
      const originalType = `${data.stock_type}`;

      const stockName = calculateStockTypeName(data);

      let offering = {
        additional_info: {
          annual_rate: Number(data.annual_rate),
          blocker: data.blocker
            ? BLOCKERS_OPTIONS.filter((x) => x.value === data.blocker)[0]
            : null,
          default_rate: Number(data.default_rate),
          oic_amount:
            Number(data.oic_percentage / 100) * Number(data.total_amount),
          oic_percentage: Number(data.oic_percentage),
          original_principle_amount: Number(data?.original_principle_amount),
          term: Number(data.term),
          term_2: Number(data.term_2),
          maturity_date: data.maturity_date,
          enter_maturity_date: data.enter_maturity_date,
          safe_sub_type: data.safe_sub_type,
          in_lieu_of_cash_sub_type: data.in_lieu_of_cash_sub_type,
          in_lieu_class_subtype: data.in_lieu_class_subtype,
          unit_sub_type1: data.unit_sub_type1,
          unit_sub_type2: data.unit_sub_type2,
          safe_pm_valuation_cap: data.safe_pm_valuation_cap,
          safe_discount: data.safe_discount,
          safe_percentage: data.safe_percentage,
          safe_amount: data.safe_amount,
          units_common_stock_share: data.units_common_stock_share,
          units_preferred_stock_shares: data.units_preferred_stock_shares,
          units_preferred_stock_shares_2: data.units_preferred_stock_shares_2,
          unit_exercise_price: data.unit_exercise_price,
          unit_exercise_price_2: data.unit_exercise_price_2,
          units_warrants_percent: data.units_warrants_percent,
          preferred_percentage: data.preferred_percentage,
          preferred_class: data.preferred_class,
          sub_type: data.sub_type,
          common_stock_class: data.common_stock_class,
          preferred_sub_type: data.preferred_sub_type,
          preferred_series: "",
        },
        issuer_id: data.issuer,
        name: data.name,
        status: "Draft",
        type: originalType,
        stock_type: stockName,
        total_amount: data.allow_total_units
          ? Number(data.total_units) *
            Number(
              data.unit_price ||
                (data.stock_type === "safe" ? 1 : data.conversion_price)
            )
          : Number(data.total_amount),
        total_units: data.allow_total_units
          ? Number(data.total_units)
          : Math.round(Number(data.total_amount)) /
            Number(
              data.unit_price ||
                (data.stock_type === "safe" ? 1 : data.conversion_price)
            ),
        unit_price:
          (data.stock_type === "safe" ? 1 : Number(data.unit_price)) ||
          (data.stock_type === "safe" ? 1 : Number(data.conversion_price)),
        batch: {
          shares: Number(data.batch_shares),
          warrants: Number(data.batch_warrants),
          unit_price: Number(data.price_per_batch),
        },
      };

      if (data.stock_type === "convertible_note") {
        offering.unit_price = Number(data.conversion_price);
      }
      if (params.id) {
        // updateOffering(params.id, offering).then((response) => {
        //   fetchOfferingData();
        // });
      } else {
        createOffering(offering)
          .then((response) => {
            refreshToken().then((response) =>
              dispatch({
                type: "user",
                payload: { ...me, token: response.token },
              })
            );
            props.history.push(`/offering/${response.id}/wizard/account`);
          })
          .catch((error) => {
            const message = `${error?.response.data.errors.join(". ")}`;
            showSpinner(false);
            if (error?.response.data.errors.length > 1) {
              toast.error(
                <div>
                  There were multiple problems with your submission: <br />
                  <br />
                  {message}
                </div>
              );
            } else {
              toast.error(
                <div>
                  There was a problem with your submission: <br />
                  <br />
                  {message}
                </div>
              );
            }
          });
      }
    }
  };

  return (
    <>
      <Header
        name={params.id ? "Update offering" : `Let's Get Started!`}
        title={issuer?.name}
      ></Header>
      <div className="container-fluid">
          <Formik
            enableReinitialize={true}
            initialValues={formData}
            validationSchema={createOfferingSchema}
            onSubmit={handleCreateOffering}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              setFieldValue,
              touched,
            }) => {
              const selectedIssuer = values.issuer
                ? issuersOptions.filter((x) => x.value === values.issuer)[0]
                : null;
              const selectedStockType = values.stock_type
                ? STOCK_TYPE_OPTIONS.filter(
                    (x) => x.value === values.stock_type
                  )[0]
                : null;
              const selectedBlocker = values.blocker
                ? BLOCKERS_OPTIONS.filter((x) => x.value === values.blocker)[0]
                : null;
              const selectedCommonStock = values.sub_type
                ? COMMON_STOCK_OPTIONS.filter(
                    (x) => x.value === values.sub_type
                  )[0]
                : null;
              const selectedpreferred_stock = values.preferred_sub_type
                ? PREFERRED_STOCK_OPTIONS.filter(
                    (x) => x.value === values.preferred_sub_type
                  )[0]
                : null;
              const selectedpreferred_series = values.preferred_series
                ? PREFERRED_SERIES_OPTIONS.filter(
                    (x) => x.value === values.preferred_series
                  )[0]
                : null;
              const selectedUnitSubType1 = values.unit_sub_type1
                ? UNIT_DROPDOWN1_OPTIONS.filter(
                    (x) => x.value === values.unit_sub_type1
                  )[0]
                : null;
              const selectedUnitSubType2 = values.unit_sub_type2
                ? UNIT_DROPDOWN2_OPTIONS.filter(
                    (x) => x.value === values.unit_sub_type2
                  )[0]
                : null;
              const selectedSafeSubType = values.safe_sub_type
                ? SAFE_DROPDOWN_OPTIONS.filter(
                    (x) => x.value === values.safe_sub_type
                  )[0]
                : null;
              const inLieuOfCashSubType = values.in_lieu_of_cash_sub_type
                ? ILOC_DROPDOWN_OPTIONS.filter(
                    (x) => x.value === values.in_lieu_of_cash_sub_type
                  )[0]
                : null;
              const inLieuClass = values.in_lieu_class_subtype
                ? IN_LIEU_SUBTYPE_CLASS_OPTIONS.filter(
                    (x) => x.value === values.in_lieu_class_subtype
                  )[0]
                : null;

              const updatedTotalValue = Number(
                values.unit_price * values.total_units
              );

              return (
                <form noValidate onSubmit={handleSubmit}>
                  <FormControl
                    type="text"
                    name="name"
                    title="Offering Name"
                    required={true}
                    value={values.name}
                    error={errors.name}
                    onChange={handleChange}
                  />
                  <FormControl
                    type="select"
                    name="issuer"
                    title="Issuer"
                    required={true}
                    options={issuersOptions}
                    value={selectedIssuer}
                    error={errors.issuer}
                    onChange={handleChange}
                    disabled={params.id && true}
                  />
                  <FormControl
                    type="select"
                    name="stock_type"
                    title="Security Type"
                    required={true}
                    options={STOCK_TYPE_OPTIONS}
                    value={selectedStockType}
                    error={errors.stock_type}
                    onChange={handleChange}
                    disabled={params.id && true}
                  />
                  {values.stock_type === "common_stock" && (
                    <>
                      <FormControl
                        type="select"
                        name="sub_type"
                        title="Sub Type"
                        options={COMMON_STOCK_OPTIONS}
                        value={selectedCommonStock}
                        error={errors.sub_type}
                        onChange={handleChange}
                        required={true}
                        disabled={params.id && true}
                      />

                      {values.sub_type === "class" && (
                        <>
                          <IconControl
                            placement="right"
                            iconName="fe fe-info ms-2"
                            required=" *"
                            inputTitle="Class"
                            tooltipTitle="Please indicate the Class by entering the Class letter (e.g. A, B, etc) or number (e.g. C-1, C.10, etc)"
                            disabled={params.id && true}
                          />
                          <FormControl
                            type="text"
                            name="common_stock_class"
                            value={values.common_stock_class}
                            error={errors.common_stock_class}
                            onChange={handleChange}
                            maxLength={4}
                            required={true}
                            disabled={params.id && true}
                          />
                        </>
                      )}
                      <FormControl
                        type="checkbox"
                        name="allow_total_units"
                        label="Would you like to define number of shares offered?"
                        value={values.allow_total_units}
                        onChange={handleChange}
                      />
                      <div className="row">
                        <div className="col-lg-6">
                          {values.allow_total_units ? (
                            <FormControl
                              type="number"
                              name="total_units"
                              title="Number Offered"
                              required={true}
                              value={values.total_units}
                              error={errors.total_units}
                              onChange={handleChange}
                            />
                          ) : (
                            <FormControl
                              type="money"
                              name="total_amount"
                              title="Total Offering Amount (USD)"
                              required={true}
                              decimalScale={2}
                              fixedDecimalScale={2}
                              value={toMoney(values.total_amount)}
                              error={errors.total_amount}
                              onChange={handleChange}
                            />
                          )}
                        </div>
                        <div className="col-lg-6">
                          <FormControl
                            type="money"
                            name="unit_price"
                            title="Price per Share"
                            decimalScale={2}
                            fixedDecimalScale={2}
                            required={true}
                            value={parseFloat(values.unit_price)}
                            error={errors.unit_price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {values.stock_type === "convertible_note" && (
                    <>
                      <FormControl
                        type="money"
                        name="total_amount"
                        title="Total Offering Amount (USD)"
                        required={true}
                        decimalScale={2}
                        fixedDecimalScale={2}
                        value={values.total_amount}
                        error={errors.total_amount}
                        onChange={(value) => {
                          handleChange(value);
                          setFieldValue("oic_percentage", "0");
                          setFieldValue("oic_amount", "0");
                        }}
                      />
                      <div className="row">
                        <div className="col">
                          <FormControl
                            type="percent"
                            name="oic_percentage"
                            title="Original Issuance Discount (%)"
                            value={toPercent(values?.oic_percentage)}
                            error={errors.oic_percentage}
                            onChange={(value) => {
                              handleChange(value);
                              setFieldValue(
                                "oic_amount",
                                Math.round((value / 100) * values.total_amount)
                              );
                            }}
                            disabled={params.id && true}
                          />
                        </div>
                        <div className="col">
                          <FormControl
                            type="money"
                            name="oic_amount"
                            title="Original Issuance Discount (USD)"
                            disabled={true}
                            decimalScale={2}
                            fixedDecimalScale={2}
                            value={(
                              (values.oic_percentage / 100) *
                              values.total_amount
                            )}
                            error={errors.oic_amount}
                            onChange={handleChange}
                          />
                        </div>
                        <div>
                          <FormControl
                            type="money"
                            name="original_principle_amount"
                            title="Original Principal Amount"
                            disabled={true}
                            decimalScale={2}
                            fixedDecimalScale={2}
                            value={(
                              values?.total_amount +
                              (values?.oic_percentage / 100) *
                                values?.total_amount
                            )}
                            error={errors?.original_principle_amount}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <FormControl
                        type="checkbox"
                        name="enter_maturity_date"
                        label="Would you like to define Maturity Date instead?"
                        value={values.enter_maturity_date}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("term", "");
                        }}
                      />
                      {values.enter_maturity_date ? (
                        <FormControl
                          type="date"
                          name="maturity_date"
                          title="Maturity Date"
                          minDate={moment().toDate()}
                          dateFormat="MMM d, yyyy h:mm aa"
                          selected={
                            values.date ? moment(values.date).toDate() : ""
                          }
                          filterTime
                          required={true}
                          value={values.maturity_date}
                          error={errors.maturity_date}
                          onChange={handleChange}
                          disabled={params.id && true}
                        />
                      ) : (
                        <div className="mb-4">
                          <label className="form-label mb-0">
                            Term (In Months)
                            <span className="text-danger"> *</span>
                          </label>
                          <NumberFormat
                            name="term"
                            value={parseInt(values.term).toFixed(0)}
                            onChange={handleChange}
                            disabled={params.id && true}
                            className="form-control"
                            maxLength={2}
                          />
                          {errors.term && touched.term && (
                            <div className="text-danger mt-2 ms-1 h5">
                              {errors.term}
                            </div>
                          )}
                        </div>
                      )}
                      <div className="row">
                        <div className="col">
                          <FormControl
                            type="percent"
                            name="annual_rate"
                            title="Annual Interest Rate"
                            required={true}
                            value={toPercent(values?.annual_rate)}
                            error={errors.annual_rate}
                            onChange={handleChange}
                            disabled={params.id && true}
                          />
                        </div>
                        <div className="col">
                          <FormControl
                            type="percent"
                            name="default_rate"
                            title="Default Interest Rate"
                            required={true}
                            value={toPercent(values?.default_rate)}
                            error={errors.default_rate}
                            onChange={handleChange}
                            disabled={params.id && true}
                          />
                        </div>
                      </div>

                      <FormControl
                        type="money"
                        name="conversion_price"
                        title="Conversion Price"
                        required={true}
                        decimalScale={2}
                        fixedDecimalScale={2}
                        value={
                          values.conversion_price &&
                          values.conversion_price.toFixed(2)
                        }
                        error={errors.conversion_price}
                        onChange={handleChange}
                      />

                      <FormControl
                        type="select"
                        name="blocker"
                        title="Beneficial Ownership Limitation (Blocker)"
                        required={true}
                        options={BLOCKERS_OPTIONS}
                        value={selectedBlocker}
                        error={errors.blocker}
                        onChange={handleChange}
                        disabled={params.id && true}
                      />
                    </>
                  )}
                  {values.stock_type === "preferred_stock" && (
                    <>
                      <FormControl
                        type="select"
                        name="preferred_sub_type"
                        title="Sub Type"
                        required={true}
                        options={PREFERRED_STOCK_OPTIONS}
                        value={selectedpreferred_stock}
                        error={errors.preferred_sub_type}
                        onChange={handleChange}
                        disabled={params.id && true}
                      />

                      {values.preferred_sub_type === "series" && (
                        <>
                          <div className="row">
                            <div className="col-lg-6">
                              <FormControl
                                type="text"
                                name="preferred_class"
                                maxLength={4}
                                title="Class"
                                value={values.preferred_class}
                                error={errors.preferred_class}
                                onChange={handleChange}
                                required={true}
                                disabled={params.id && true}
                              />
                            </div>
                            <div className="col-lg-6">
                              <FormControl
                                type="select"
                                name="preferred_series"
                                title="Series"
                                options={PREFERRED_SERIES_OPTIONS}
                                value={selectedpreferred_series}
                                error={errors.preferred_series}
                                onChange={handleChange}
                                required={true}
                                disabled={params.id && true}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {values.preferred_series === "percentage" && (
                        <FormControl
                          type="percent"
                          name="preferred_percentage"
                          title="Percentage"
                          value={values.preferred_percentage}
                          error={errors.preferred_percentage}
                          onChange={handleChange}
                          maxLength={3}
                          required={true}
                          disabled={params.id && true}
                        />
                      )}
                      <FormControl
                        type="checkbox"
                        name="allow_total_units"
                        label="Would you like to define number of shares offered?"
                        value={values.allow_total_units}
                        onChange={handleChange}
                      />
                      <div className="row">
                        <div className="col-lg-6">
                          {values.allow_total_units ? (
                            <FormControl
                              type="number"
                              name="total_units"
                              title="Number Offered"
                              required={true}
                              value={values.total_units}
                              error={errors.total_units}
                              onChange={handleChange}
                            />
                          ) : (
                            <FormControl
                              type="money"
                              name="total_amount"
                              title="Total Offering Amount (USD)"
                              required={true}
                              decimalScale={2}
                              fixedDecimalScale={2}
                              value={toMoney(values.total_amount)}
                              error={errors.total_amount}
                              onChange={handleChange}
                            />
                          )}
                        </div>
                        <div className="col-lg-6">
                          <FormControl
                            type="money"
                            name="unit_price"
                            title="Price per Share"
                            decimalScale={2}
                            fixedDecimalScale={2}
                            required={true}
                            value={parseFloat(values.unit_price)}
                            error={errors.unit_price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {values.stock_type === "unit" && (
                    <>
                      <div className="row">
                        <div className="col-lg-6">
                          <FormControl
                            type="number"
                            name="total_units"
                            title="Units Offered"
                            decimalSeparator={false}
                            isAllowed={(values) =>
                              isFloatValueAllowed(
                                values,
                                FLOAT_VAULT.HUNDRED_MILLION
                              )
                            }
                            required={true}
                            value={values.total_units}
                            error={errors.total_units}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue("allow_total_units", true);
                            }}
                          />
                        </div>
                        <div className="col-lg-6">
                          <FormControl
                            type="money"
                            name="unit_price"
                            title="Price per unit"
                            decimalScale={2}
                            fixedDecimalScale={2}
                            required={true}
                            isAllowed={(values) =>
                              isFloatValueAllowed(
                                values,
                                FLOAT_VAULT.HUNDRED_THOUSAND
                              )
                            }
                            value={parseFloat(values.unit_price)}
                            error={errors.unit_price}
                            onChange={handleChange}
                          />
                        </div>

                        <FormControl
                          type="select"
                          name="unit_sub_type1"
                          title="Sub Type 1"
                          required={true}
                          options={UNIT_DROPDOWN1_OPTIONS}
                          value={selectedUnitSubType1}
                          error={errors.unit_sub_type1}
                          onChange={handleChange}
                        />

                        {values.unit_sub_type1 === "unit_preferred_stock" && (
                          <>
                            <FormControl
                              type="number"
                              name="units_preferred_stock_shares"
                              title="Quantity of share per unit"
                              isAllowed={(values) =>
                                isFloatValueAllowed(
                                  values,
                                  FLOAT_VAULT.HUNDRED_THOUSAND
                                )
                              }
                              decimalSeparator={false}
                              value={values.units_preferred_stock_shares}
                              error={errors.units_preferred_stock_shares}
                              onChange={handleChange}
                              required={true}
                            />
                          </>
                        )}

                        {values.unit_sub_type1 === "unit_common_stock" && (
                          <>
                            <div className="mb-4">
                              <label className="form-label mb-0">
                                Quantity of share per unit
                                <span className="text-danger"> *</span>
                              </label>
                              <NumberFormat
                                name="units_common_stock_share"
                                value={values.units_common_stock_share}
                                error={errors.units_common_stock_share}
                                decimalSeparator={false}
                                onChange={handleChange}
                                disabled={params.id && true}
                                className="form-control"
                                maxLength={3}
                              />
                              {errors.units_common_stock_share &&
                                touched.units_common_stock_share && (
                                  <div className="text-danger mt-2 ms-1 h5">
                                    {errors.units_common_stock_share}
                                  </div>
                                )}
                            </div>
                          </>
                        )}
                        {values.unit_sub_type1 === "unit_convertable_notes" && (
                          <>
                            <FormControl
                              type="money"
                              name="total_amount"
                              title="Offering Amount Per Unit (USD)"
                              required={true}
                              value={
                                values.total_amount &&
                                values.total_amount.toFixed(2)
                              }
                              isAllowed={(values) =>
                                isFloatValueAllowed(
                                  values,
                                  FLOAT_VAULT.ONE_MILLION
                                )
                              }
                              error={errors.total_amount}
                              onChange={(value) => {
                                handleChange(value);
                                setFieldValue("oic_percentage", "0");
                                setFieldValue("oic_amount", "0");
                              }}
                            />
                            <div className="row">
                              <div className="col">
                                <FormControl
                                  type="percent"
                                  name="oic_percentage"
                                  title="Original Issuance Discount (%)"
                                  value={toPercent(values?.oic_percentage)}
                                  error={errors.oic_percentage}
                                  isAllowed={(values) =>
                                    isFloatValueAllowed(
                                      values,
                                      FLOAT_VAULT.HUNDRED
                                    )
                                  }
                                  onChange={(value) => {
                                    handleChange(value);
                                    setFieldValue(
                                      "oic_amount",
                                      Math.round(
                                        (value / 100) * values.total_amount
                                      )
                                    );
                                  }}
                                  disabled={params.id && true}
                                />
                              </div>
                              <div className="col">
                                <FormControl
                                  type="money"
                                  name="original_principle_amount"
                                  title="Original Principal Amount"
                                  decimalScale={2}
                                  fixedDecimalScale={2}
                                  disabled
                                  value={(
                                    updatedTotalValue +
                                    (values?.oic_percentage / 100) *
                                      updatedTotalValue
                                  )}
                                  error={errors?.original_principle_amount}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>

                            <div className="mb-4">
                              <label className="form-label mb-0">
                                Term (In Months)
                                <span className="text-danger"> *</span>
                              </label>
                              <NumberFormat
                                name="term"
                                value={parseInt(values.term).toFixed(0)}
                                onChange={handleChange}
                                decimalSeparator={false}
                                disabled={params.id && true}
                                className="form-control"
                                maxLength={2}
                              />
                              {errors.term && touched.term && (
                                <div className="text-danger mt-2 ms-1 h5">
                                  {errors.term}
                                </div>
                              )}
                            </div>

                            <div className="row">
                              <div className="col">
                                <FormControl
                                  type="percent"
                                  name="annual_rate"
                                  title="Annual Interest Rate"
                                  required={true}
                                  value={toPercent(values?.annual_rate)}
                                  error={errors.annual_rate}
                                  onChange={handleChange}
                                  disabled={params.id && true}
                                />
                              </div>
                              <div className="col">
                                <FormControl
                                  type="percent"
                                  name="default_rate"
                                  title="Default Interest Rate"
                                  required={true}
                                  value={toPercent(values?.default_rate)}
                                  error={errors.default_rate}
                                  onChange={handleChange}
                                  disabled={params.id && true}
                                />
                              </div>
                            </div>

                            <FormControl
                              type="money"
                              name="conversion_price"
                              title="Conversion Price"
                              required={true}
                              value={
                                values.conversion_price &&
                                values.conversion_price.toFixed(2)
                              }
                              error={errors.conversion_price}
                              onChange={handleChange}
                            />

                            <FormControl
                              type="select"
                              name="blocker"
                              title="Beneficial Ownership Limitation (Blocker)"
                              required={true}
                              options={BLOCKERS_OPTIONS}
                              value={selectedBlocker}
                              error={errors.blocker}
                              onChange={handleChange}
                              disabled={params.id && true}
                            />
                          </>
                        )}
                        <FormControl
                          type="select"
                          name="unit_sub_type2"
                          title="Sub Type 2"
                          required={true}
                          options={UNIT_DROPDOWN2_OPTIONS}
                          value={selectedUnitSubType2}
                          error={errors.unit_sub_type2}
                          onChange={handleChange}
                        />

                        {values.unit_sub_type2 === "with_warrants" && (
                          <>
                            <FormControl
                              type="percent"
                              name="units_warrants_percent"
                              title="Percent"
                              isAllowed={(values) =>
                                isFloatValueAllowed(values, FLOAT_VAULT.HUNDRED)
                              }
                              value={values.units_warrants_percent}
                              error={errors.units_warrants_percent}
                              onChange={handleChange}
                              required={true}
                              maxLength={4}
                            />

                            <div className="col-6">
                              <FormControl
                                type="money"
                                name="unit_exercise_price"
                                title="Exercise Price"
                                isAllowed={(values) =>
                                  isFloatValueAllowed(
                                    values,
                                    FLOAT_VAULT.HUNDRED_MILLION
                                  )
                                }
                                value={parseFloat(
                                  values.unit_exercise_price
                                ).toFixed(2)}
                                decimalScale={2}
                                fixedDecimalScale={2}
                                error={errors.unit_exercise_price}
                                onChange={handleChange}
                                required={true}
                              />
                            </div>
                            <div className="col-6">
                              <div className="mb-4">
                                <label className="form-label mb-0">
                                  Term (In Months)
                                  <span className="text-danger"> *</span>
                                </label>
                                <NumberFormat
                                  name="term"
                                  value={parseInt(values.term).toFixed(0)}
                                  onChange={handleChange}
                                  disabled={params.id && true}
                                  className="form-control"
                                  maxLength={2}
                                />
                                {errors.term && touched.term && (
                                  <div className="text-danger mt-2 ms-1 h5">
                                    {errors.term}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-6">
                              <FormControl
                                type="money"
                                name="unit_exercise_price_2"
                                title="Exercise Price 2"
                                decimalScale={2}
                                fixedDecimalScale={2}
                                isAllowed={(values) =>
                                  isFloatValueAllowed(
                                    values,
                                    FLOAT_VAULT.HUNDRED_MILLION
                                  )
                                }
                                value={(() => {
                                  const val = parseFloat(
                                    values.unit_exercise_price_2
                                  ).toFixed(2);

                                  return isNaN(val) ? null : val;
                                })()}
                                error={errors.unit_exercise_price_2}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-6">
                              <div className="mb-4">
                                <label className="form-label mb-0">
                                  Term 2 (In Months)
                                </label>
                                <NumberFormat
                                  name="term_2"
                                  value={parseInt(values.term_2).toFixed(0)}
                                  onChange={handleChange}
                                  disabled={params.id && true}
                                  decimalSeparator={false}
                                  className="form-control"
                                  maxLength={2}
                                />
                                {errors.term_2 && touched.term_2 && (
                                  <div className="text-danger mt-2 ms-1 h5">
                                    {errors.term_2}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        {values.unit_sub_type2 === "unit_preferred_stock" && (
                          <>
                            <FormControl
                              type="number"
                              name="units_preferred_stock_shares_2"
                              title="Quantity of share per unit"
                              value={values.units_preferred_stock_shares_2}
                              error={errors.units_preferred_stock_shares_2}
                              onChange={handleChange}
                              required={true}
                            />
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {values.stock_type === "safe" && (
                    <>
                      <FormControl
                        type="select"
                        name="safe_sub_type"
                        title="Sub Type"
                        required={true}
                        options={SAFE_DROPDOWN_OPTIONS}
                        value={selectedSafeSubType}
                        error={errors.safe_sub_type}
                        onChange={handleChange}
                        disabled={params.id && true}
                      />
                      {values.safe_sub_type === "post_money_valuation_cap" && (
                        <FormControl
                          type="money"
                          name="safe_pm_valuation_cap"
                          title="Post-Money Valuation Cap"
                          value={values.safe_pm_valuation_cap}
                          error={errors.safe_pm_valuation_cap}
                          onChange={handleChange}
                          required={true}
                          decimalScale={2}
                          fixedDecimalScale={2}
                        />
                      )}
                      {values.safe_sub_type === "discount_rate" && (
                        <FormControl
                          type="percent"
                          name="safe_discount"
                          title="Value %"
                          value={values.safe_discount}
                          error={errors.safe_discount}
                          onChange={handleChange}
                          maxLength={3}
                          required={true}
                        />
                      )}
                      {values.safe_sub_type ===
                        "valuation_cap_with_discount" && (
                        <>
                          <div className="row">
                            <div className="col-lg-6">
                              <FormControl
                                type="money"
                                name="safe_amount"
                                title="Value (USD)"
                                value={values.safe_amount}
                                error={errors.safe_amount}
                                onChange={handleChange}
                                required={true}
                                decimalScale={2}
                                fixedDecimalScale={2}
                                disabled={params.id && true}
                              />
                            </div>
                            <div className="col-lg-6">
                              <FormControl
                                type="percent"
                                name="safe_percentage"
                                title="Value %"
                                maxLength={3}
                                value={values.safe_percentage}
                                error={errors.safe_percentage}
                                onChange={handleChange}
                                required={true}
                                disabled={params.id && true}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div>
                        <FormControl
                          type="money"
                          name="total_amount"
                          maxLength={3}
                          title="Total Offering Amount (USD)"
                          required={true}
                          decimalScale={2}
                          fixedDecimalScale={2}
                          value={values.total_amount}
                          error={errors.total_amount}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  )}

                  {values.stock_type === "in_lieu_of_cash" && (
                    <>
                      <FormControl
                        type="select"
                        name="in_lieu_of_cash_sub_type"
                        title="Sub Type"
                        required={true}
                        options={ILOC_DROPDOWN_OPTIONS}
                        value={inLieuOfCashSubType}
                        error={errors.in_lieu_of_cash_sub_type}
                        onChange={handleChange}
                      />
                      {values.in_lieu_of_cash_sub_type ===
                        "in_lieu_of_cash_common_stock" && (
                        <>
                          <FormControl
                            type="select"
                            name="in_lieu_class_subtype"
                            title="Class"
                            options={IN_LIEU_SUBTYPE_CLASS_OPTIONS}
                            value={inLieuClass}
                            error={errors.in_lieu_class_subtype}
                            onChange={handleChange}
                            required={true}
                          />

                          {values.in_lieu_class_subtype === "class" && (
                            <>
                              <IconControl
                                placement="right"
                                iconName="fe fe-info ms-2"
                                required={true}
                                inputTitle="Class"
                                tooltipTitle="Please indicate the Class by entering the Class letter (e.g. A, B, etc) or number (e.g. C-1, C.10, etc)"
                              />
                              <FormControl
                                type="text"
                                name="common_stock_in_lieu_class"
                                value={values.common_stock_in_lieu_class}
                                error={errors.common_stock_in_lieu_class}
                                onChange={handleChange}
                                maxLength={4}
                                required={true}
                              />
                            </>
                          )}
                          <FormControl
                            type="checkbox"
                            name="allow_total_units"
                            label="Would you like to define number of shares offered?"
                            value={values.allow_total_units}
                            onChange={handleChange}
                          />
                          <div className="row">
                            <div className="col-lg-6">
                              {values.allow_total_units ? (
                                <FormControl
                                  type="number"
                                  name="total_units"
                                  title="Number Offered"
                                  required={true}
                                  value={values.total_units}
                                  error={errors.total_units}
                                  onChange={handleChange}
                                />
                              ) : (
                                <FormControl
                                  type="money"
                                  name="total_amount"
                                  title="Total Offering Amount (USD)"
                                  required={true}
                                  value={toMoney(values.total_amount)}
                                  error={errors.total_amount}
                                  onChange={handleChange}
                                />
                              )}
                            </div>
                            <div className="col-lg-6">
                              <FormControl
                                type="money"
                                name="unit_price"
                                title="Price per Share"
                                decimalScale={2}
                                fixedDecimalScale={2}
                                required={true}
                                value={parseFloat(values.unit_price)}
                                error={errors.unit_price}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {values.in_lieu_of_cash_sub_type ===
                        "in_lieu_of_cash_convertible_note" && (
                        <>
                          <FormControl
                            type="money"
                            name="total_amount"
                            title="Total Offering Amount (USD)"
                            required={true}
                            decimalScale={2}
                            fixedDecimalScale={2}
                            value={values.total_amount}
                            error={errors.total_amount}
                            onChange={(value) => {
                              handleChange(value);
                              setFieldValue("oic_percentage", "0");
                              setFieldValue("oic_amount", "0");
                            }}
                          />
                          <div className="row">
                            <div className="col">
                              <FormControl
                                type="percent"
                                name="oic_percentage"
                                title="Original Issuance Discount (%)"
                                value={toPercent(values?.oic_percentage)}
                                error={errors.oic_percentage}
                                onChange={(value) => {
                                  handleChange(value);
                                  setFieldValue(
                                    "oic_amount",
                                    Math.round(
                                      (value / 100) * values.total_amount
                                    )
                                  );
                                }}
                                disabled={params.id && true}
                              />
                            </div>
                            <div className="col">
                              <FormControl
                                type="money"
                                name="oic_amount"
                                title="Original Issuance Discount (USD)"
                                disabled={true}
                                decimalScale={2}
                                fixedDecimalScale={2}
                                value={(
                                  (values.oic_percentage / 100) *
                                  values.total_amount
                                )}
                                error={errors.oic_amount}
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <FormControl
                                type="money"
                                name="original_principle_amount"
                                title="Original Principal Amount"
                                disabled={true}
                                decimalScale={2}
                                fixedDecimalScale={2}
                                value={(
                                  values?.total_amount +
                                  (values?.oic_percentage / 100) *
                                    values?.total_amount
                                )}
                                error={errors?.original_principle_amount}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <FormControl
                            type="checkbox"
                            name="enter_maturity_date"
                            label="Would you like to define Maturity Date instead?"
                            value={values.enter_maturity_date}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue("term", "");
                            }}
                          />
                          {values.enter_maturity_date ? (
                            <FormControl
                              type="date"
                              name="maturity_date"
                              title="Maturity Date"
                              minDate={moment().toDate()}
                              dateFormat="MMM d, yyyy h:mm aa"
                              selected={
                                values.date ? moment(values.date).toDate() : ""
                              }
                              filterTime
                              required={true}
                              value={values.maturity_date}
                              error={errors.maturity_date}
                              onChange={handleChange}
                              disabled={params.id && true}
                            />
                          ) : (
                            <div className="mb-4">
                              <label className="form-label mb-0">
                                Term (In Months)
                                <span className="text-danger"> *</span>
                              </label>
                              <NumberFormat
                                name="term"
                                value={parseInt(values.term).toFixed(0)}
                                onChange={handleChange}
                                disabled={params.id && true}
                                className="form-control"
                                maxLength={2}
                              />
                              {errors.term && touched.term && (
                                <div className="text-danger mt-2 ms-1 h5">
                                  {errors.term}
                                </div>
                              )}
                            </div>
                          )}
                          <div className="row">
                            <div className="col">
                              <FormControl
                                type="percent"
                                name="annual_rate"
                                title="Annual Interest Rate"
                                required={true}
                                value={toPercent(values?.annual_rate)}
                                error={errors.annual_rate}
                                onChange={handleChange}
                                disabled={params.id && true}
                              />
                            </div>
                            <div className="col">
                              <FormControl
                                type="percent"
                                name="default_rate"
                                title="Default Interest Rate"
                                required={true}
                                value={toPercent(values?.default_rate)}
                                error={errors.default_rate}
                                onChange={handleChange}
                                disabled={params.id && true}
                              />
                            </div>
                          </div>

                          <FormControl
                            type="money"
                            name="conversion_price"
                            title="Conversion Price"
                            required={true}
                            value={
                              values.conversion_price &&
                              values.conversion_price.toFixed(2)
                            }
                            error={errors.conversion_price}
                            onChange={handleChange}
                          />

                          <FormControl
                            type="select"
                            name="blocker"
                            title="Beneficial Ownership Limitation (Blocker)"
                            required={true}
                            options={BLOCKERS_OPTIONS}
                            value={selectedBlocker}
                            error={errors.blocker}
                            onChange={handleChange}
                            disabled={params.id && true}
                          />
                        </>
                      )}
                    </>
                  )}
                  <Loader show={spinner}>
                    <button className="btn btn-eq-primary" type="submit">
                      {params.id ? "Update Offering" : "Create Offering"}
                    </button>
                  </Loader>
                </form>
              );
            }}
          </Formik>
      </div>
    </>
  );
};

export default CreateOffering;
