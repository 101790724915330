import
  {
    Box,
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Tab,
    Table,
    TableContainer,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast,
    VStack,
  } from "@chakra-ui/react";
import
  {
    useInvestmentAgreementSignatureRepair,
    useInvestmentAgreementSupport,
  } from "@equidefi/portals/hooks/useAgreements";
import { userFullName } from "@equidefi/shared";
import { Heading, Paper } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";
import React from "react";

const MatchIcon = ({ isMatch }) => {
  return isMatch ? (
    <Box as="span" color="green">
      <Icon.UserCheck size="1em" />
    </Box>
  ) : (
    <Box as="span" color="red">
      <Icon.XCircle size="1em" />
    </Box>
  );
};

const MatchRow = ({ matches = {}, label, value }) => {
  return (
    <Tr>
      <Td>{label}</Td>
      <Td>
        <MatchIcon isMatch={!!matches?.[value]} />
      </Td>
    </Tr>
  );
};

export const InvestmentAgreementSupportModal = ({
  investmentAgreementId,
  isOpen = false,
  onClose = () => {},
}) => {
  const toast = useToast();
  const repair = useInvestmentAgreementSignatureRepair(investmentAgreementId);
  const { data: support, refetch } = useInvestmentAgreementSupport(
    investmentAgreementId,
    {
      enabled: !!(isOpen && investmentAgreementId),
    }
  );

  const handleRepair = async (email) => {
    try {
      await repair.mutateAsync({ email });
      refetch();
      toast({
        status: "success",
        description: "Successfully repaired signing party",
      });
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description:
          "Could not repair signing party. Contact engineering for help.",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} size="2xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Agreement Support</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {support?.summary ? (
            <Tabs>
              <TabList>
                <Tab>Signer List</Tab>
                <Tab>JSON</Tab>
              </TabList>
              <TabPanels>
                <TabPanel overflowY="scroll" maxH="75vh">
                  <VStack w="full" gap="3">
                    {Object.entries(support?.summary?.matches ?? {}).map(
                      ([key, data]) => {
                        return (
                          <Paper p="3" w="full" key={key}>
                            <Heading textStyle="h3" as="h3">
                              <HStack justify="space-between" w="full">
                                <div>
                                  {data?.signer?.signerName ??
                                    userFullName(data?.signing_party)}
                                </div>

                                <Button
                                  colorScheme="gray"
                                  size="xs"
                                  isLoading={repair.isLoading}
                                  onClick={() =>
                                    handleRepair(
                                      data?.signer?.signerEmailAddress
                                    )
                                  }
                                >
                                  Repair
                                </Button>
                              </HStack>
                            </Heading>
                            <TableContainer>
                              <Table size="sm">
                                <Thead>
                                  <Tr>
                                    <Th>Property</Th>
                                    <Th>Sync</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  <MatchRow
                                    matches={data?.matches}
                                    label="Name"
                                    value="name"
                                  />
                                  <MatchRow
                                    matches={data?.matches}
                                    label="Email"
                                    value="email"
                                  />
                                  <MatchRow
                                    matches={data?.matches}
                                    label="Investment Agreement ID"
                                    value="investment_agreement_id"
                                  />
                                  <MatchRow
                                    matches={data?.matches}
                                    label="Signature ID"
                                    value="signature_id"
                                  />
                                  <MatchRow
                                    matches={data?.matches}
                                    label="Role"
                                    value="role"
                                  />
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </Paper>
                        );
                      }
                    )}
                  </VStack>
                </TabPanel>
                <TabPanel>
                  <Box bg="gray.200" p="4" borderRadius="md">
                    <pre>
                      {JSON.stringify(support?.summary?.matches, null, 2)}
                    </pre>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : (
            <Spinner show />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
