export const PersonaDashboardBase = new URL("https://app.withpersona.com");

const INQUIRY_APPROVED = "approved";
const INQUIRY_DECLINED = "declined";
const INQUIRY_FOR_REVIEW = "needs_review";
const INQUIRY_COMPLETED = "completed";
const INQUIRY_PENDING = "pending";
const INQUIRY_CREATED = "created";
const INQUIRY_EXPIRED = "expired";

export const INQUIRY_STATUS_ALERT_TYPE = {
  [INQUIRY_COMPLETED]: "info",
  [INQUIRY_APPROVED]: "green",
  [INQUIRY_FOR_REVIEW]: "teal",
  [INQUIRY_PENDING]: "gray",
  [INQUIRY_CREATED]: "gray",
  [INQUIRY_DECLINED]: "red",
  [INQUIRY_EXPIRED]: "orange",
};

export const INQUIRY_STATUS_TEXT = {
  [INQUIRY_COMPLETED]: "Completed",
  [INQUIRY_APPROVED]: "Approved",
  [INQUIRY_DECLINED]: "Declined",
  [INQUIRY_FOR_REVIEW]: "Needs Review",
  [INQUIRY_PENDING]: "Pending",
  [INQUIRY_CREATED]: "Created",
  [INQUIRY_EXPIRED]: "Expired",
};

export const INQUIRY_STATUSES = [
  INQUIRY_APPROVED,
  INQUIRY_DECLINED,
  INQUIRY_FOR_REVIEW,
  INQUIRY_COMPLETED,
  INQUIRY_PENDING,
  INQUIRY_CREATED,
  INQUIRY_EXPIRED,
];
